<template>
    <div>
        <div class="list_cus_new container mt-4">
            <h5 style="font-weight: 700; font-size: 22px; color: #515151; margin-bottom: 20px; font-family: Libre Bodoni;" >Duyệt đăng ký tham gia đấu giá</h5>
            <b-row>
                <b-col cols="lg-5">
                    <div class="list">
                        <h5 class="mb-3">Danh sách đăng ký tham gia đấu giá</h5>
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex">
                                <b-form class="search-form" style="width: 100%;">
                                    <b-form-group>
                                        <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm" required></b-form-input>
                                    </b-form-group>
                                    <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                                </b-form>
                                <!-- <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button> -->
                            </div>
                        </div>
                        <div class="cus-item" v-for="(item, index) in dataCustomer" :key="index" style="cursor: pointer;" @click="getDetail(item.member_id)">
                            <div class="cus-info d-flex">
                                <img :src="$storage + item.avatar" alt="avatar" style="width: 70px; height: 70px; border-radius: 36px; margin-right: 15px;" @error="handleError">
                                <div>
                                    <p style="font-size: 18px; font-weight: 500; min-height: 21px;">
                                        <span v-if="item.type == 1">{{ item.member_name }}</span>
                                        <span v-else>{{ item.rep_name }}</span>
                                    </p>
                                    <p>Mã khách hàng: <span>{{ item.member_code }}</span></p>
                                    <p v-if="item.type == 2">Tổ chức: <span>{{ item.member_name }}</span></p>
                                    <p v-else>Tài khoản cá nhân</p>
                                </div>
                            </div>
                        </div>
                        <Paginate :page=page @set-page="setPage" style="margin-top: 10px;"/>
                    </div>
                </b-col>
                <b-col cols="lg-7" v-if="showDetail">
                    <div class="detail-cus">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="mb-3">Thông tin khách hàng đăng ký tham gia đấu giá</h5>
                            <button class="btn-accept" :disabled="checkDisabled.includes(0)" @click="approveMember(dataDetail.member_id)">Phê duyệt đăng ký</button>
                        </div>
                        <b-row>
                            <b-col cols="lg-4">
                                <div class="text-center">
                                    <img :src="$storage + dataDetail.avatar" @error="handleError" alt="avatar" style=" border-radius: 36px;" class="mb-2">
                                    <p class="mb-0" style="font-size: 13px;">Mã khách hàng</p>
                                    <p style="font-weight: 500;">{{ dataDetail.member_code }}</p>
                                </div>
                                <div class="file">
                                    <p>Giấy tờ liên quan:</p>
                                    <b-link :href="$storage + item.url" v-for="(item, index) in dataDetail.required_file" :key="index" target="_blank" v-if="item.url !== ''">{{ item.file_name }}</b-link>
                                </div>
                                <div class="list-access">
                                    <p style="font-size: 14px;">Hồ sơ đăng ký đấu giá:</p>
                                    <label class="container-label mb-0" v-for="(item, index) in dataDetail.required_file_status" :key="index">
                                        <p style="font-size: 13px;">{{ item.file_name }}</p>
                                        <input type="checkbox" class="input-style" :value="item.file_id" :checked="item.status == 1" @change="handleCheckboxChange(item.file_id, $event.target.checked)">
                                        <span class="checkmark"></span>
                                    </label>
                                    <div class="text-center mt-3">
                                        <button class="btn-confirm" @click="confirmMember(dataDetail.member_id)">Xác nhận</button>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="lg-8">
                                <p style="font-size: 18px; font-weight: 600;">Thông tin cá nhân</p>
                                <div class="info"><p>Họ và tên </p>
                                    <p v-if="dataDetail.type == 1">{{ dataDetail.member_name }}</p>
                                    <p v-else>{{ dataDetail.rep_name }}</p>
                                </div>
                                <div class="info"><p>Loại tài khoản </p>
                                    <p v-if="dataDetail.type == 2">Tổ chức</p>
                                    <p v-else>Cá nhân</p>
                                </div>
                                <div class="info"><p>Số điện thoại </p><p>{{ dataDetail.phone }}</p></div>
                                <div class="info"><p>Ngày sinh </p><p>{{ formateTime(dataDetail.birthday) }}</p></div>
                                <div class="info"><p>Email </p><p>{{ dataDetail.email }}</p></div>
                                <div class="info"><p>Địa chỉ </p>
                                    <p v-if="dataDetail.type == 1">{{ dataDetail.member_address +' - '+ dataDetail.ward_name +' - '+ dataDetail.district_name +' - '+ dataDetail.province_name }}</p>
                                    <p v-else>{{ dataDetail.rep_address +' - '+ dataDetail.ward_name +' - '+ dataDetail.district_name +' - '+ dataDetail.province_name }}</p>
                                </div>
                                <div class="info"><p>Giới tính</p>
                                    <p v-if="dataDetail.gender == 0">Nam</p>
                                    <p v-else>Nữ</p>
                                </div>
                                <div class="info"><p>Số CMT / Thẻ căn cước / Hộ chiếu</p><p>{{ dataDetail.id_number }}</p></div>
                                <div class="info"><p>Ngày cấp</p><p>{{ formateTime(dataDetail.id_date) }}</p></div>
                                <div class="info"><p>Nơi cấp</p><p>{{ dataDetail.id_place }}</p></div>
                                <p>Ảnh CMT / Thẻ căn cước / Hộ chiếu</p>
                                <div class="info">
                                    <div class="text-center col-lg-6">
                                        <img :src="$storage + dataDetail.id_front_face" alt="" @error="handleError" style="border-radius: 20px;">
                                        <p style="color: #757575; font-size: 12px; text-align: center; width: 100%;">Mặt trước</p>
                                    </div>
                                    <div class="text-center col-lg-6">
                                        <img :src="$storage + dataDetail.id_back_side" alt="" @error="handleError" style="border-radius: 20px;">
                                        <p style="color: #757575; font-size: 12px; text-align: center; width: 100%;">Mặt sau</p>
                                    </div>
                                </div>
                                <div v-if="dataDetail.type == 2">
                                    <p style="font-size: 18px; font-weight: 600;">Thông tin doanh nghiệp</p>
                                    <div class="info"><p>Tên doanh nghiệp</p><p>{{ dataDetail.member_name }}</p></div>
                                    <div class="info"><p>Mã số doanh nghiệp</p><p>{{ dataDetail.tax_code }}</p></div>
                                    <div class="info"><p>Ngày cấp mã số thuế</p><p>{{ formateTime(dataDetail.tax_date) }}</p></div>
                                    <div class="info"><p>Nơi cấp</p><p>{{ dataDetail.tax_place }}</p></div>
                                    <div class="info"><p>Địa chỉ</p><p>{{ dataDetail.member_address }}</p></div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
   
</template>
<script>
import Paginate from '../../../web-view/components/paginate.vue'
export default {
    components: {
        Paginate
    },
    data() {
        return {
            keySearch: '',
            dataCustomer: [],
            dataDetail: [],
            showDetail: false,
            token: '',
            page: {
                totalData: 0,
                totalPage: 0,
                currentPage: this.$route.query.page || 1,
                perPage: 10,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
            member_required_file_status: [],
            checkDisabled: [],
        }
    },
    mounted() {
        const storedData = localStorage.getItem('auInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
            this.id = info.id
        }
        this.getData()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.showDetail = false
            this.getData();
        },
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },
        async getDetail(id) {
            this.showDetail = true
            const res = await this.$axios.get('/auction/getMemberRegisterAuctionDetailAdmin', {
                params: {
                    asset_id: this.decode(this.$route.params.asset_id),
                    member_id: id
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataDetail = res.data
                this.member_required_file_status = []
                this.checkDisabled = []
                res.data.required_file_status.forEach(ele => {
                    this.checkDisabled.push(ele.status)
                    this.member_required_file_status.push({ required_file_id: ele.file_id, status: ele.status });
                });
            }
        },
        async getData() {
            const res = await this.$axios.get('/auction/getListMemberRegisterAuction', {
                params: {
                    asset_id: this.decode(this.$route.params.asset_id),
                    page: this.page.currentPage,
                    per_page: this.page.perPage,
                    approve_status: 0,
                    admin_approve_status: 1
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.page.totalData = res.data.pagination.total_element
                this.page.totalPage = res.data.pagination.total_page
                this.dataCustomer = res.data.re
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAuctioneer')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAuctioneer')
                }
            })
        },
        async confirmMember(id) {
            const data = {
                member_id: id,
                asset_id: this.decode(this.$route.params.asset_id),
                member_required_file_status: this.member_required_file_status
            }
            await this.$axios.post('/auction/confirmMemberRegisterDGV', data, {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success('Xác nhận hồ sơ thành công!')
                this.getDetail(id)
            }).catch(err => {
                this.$toast.error('Xác nhận hồ sơ không thành công!')
            })
        },
        handleCheckboxChange(id, checked) {
            if(checked) {
                checked = 1
            } else {
                checked = 0
            }
            const existingIndex = this.member_required_file_status.findIndex(item => item.required_file_id === id);
            if (existingIndex !== -1) {
                this.member_required_file_status[existingIndex].status = checked;
            } else {
                this.member_required_file_status.push({ required_file_id: id, status: checked });
            }
        },
        async approveMember(id) {
            const data = {
                asset_id: this.decode(this.$route.params.asset_id),
                member_id: id
            }
            await this.$axios.post('/auction/approveMemberRegisterDGV', data, {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success('Phê duyệt thành công!')
                this.showDetail = false
                this.getData();
            }).catch(err => {
                this.$toast.error(err.response.data.error)
            })
        }
    }
}
</script>
<style lang="css" scoped>

    .list_cus_new {
        background-image: url('@/assets/images/logo_moreinfo.png');
        background-repeat: no-repeat;
        background-position: center;
        min-height: 868px;
    }
    .list, .detail-cus {
        box-shadow: 2px 2px 6px 0px #00000026;
        background: #FFFFFF;
        padding: 10px 19px 24px 14px;
        color: #515151;
    }

    .list {
        border-radius: 15px;
    }

    .detail-cus {
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    button {
        background: none;
        border: none;
    }

    .btn-approve {
        font-size: 15px;
        font-weight: 500;
        color: #EE3439;
        border: 1px solid #EE3439;
        border-radius: 20px;
        max-height: 32px;
        margin-left: 20px;
        padding: 10px 20px;
        align-items: center;
        display: flex;
    }

    .btn-accept {
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
        background-color: #EE3439;
        border-radius: 20px;
        padding: 4px 29px;
    }

    .list-access p {
        font-weight: 500;
        margin-bottom: 10px;
    }

    .cus-item {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .cus-item p {
        margin: 0;
        font-size: 13px;
    }

    .cus-item span {
        font-size: 14px;
        font-weight: 500;
    }

    .btn-del img {
        width: 15px;
        height: 15px;
        filter: grayscale(1);
        margin-right: 20px;
    }

    .detail-cus h5 {
        font-size: 20px;
        font-weight: 500;
    }

    .file p {
        font-size: 14px;
        font-weight: 500;
    }

    .file a {
        position: relative;
        display: block;
        color: #515151;
        font-size: 14px;
        margin-bottom: 10px;
        padding-left: 30px;
    }

    .file a::before {
        position: absolute;
        content: '';
        background-image: url('@/assets/icon/Note.png');
        width: 25px;
        height: 25px;
        left: 0;
    }

    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .info p {
        font-size: 14px;
        color: #757575;
    }

    .info p:last-child {
        font-weight: 500;
        font-size: 15px;
        color: #515151;
        text-align: right;
        width: 50%;
    }

    .container-label {
        color: #515151;
        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
    }

    .container-label input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container-label input:checked ~ .checkmark:after {
        display: block;
    }

    .container-label .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/admin/icon/Done_check.png');
    }

    .btn-confirm {
        background: #EE3439;
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 16.019px;
        width: 140px;
        height: 32px;
    }

    @media (max-width: 992px) {
        .detail-cus {
            margin-top: 20px;
        }
    }
</style>

<style>
    .list .search-form {
        /* width: 363px; */
    }

    @media (max-width:425px) {
        .list .search-form {
            width: 100%;
        }
    }
</style>