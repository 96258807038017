<template>
    <div class="container payment-history">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="col-12 d-md-flex mb-2">
            <div class="title-1 col-auto align-items-start">
                Chi tiết lịch sử trả giá:
            </div>
            <label class="title-2 mr-2 " v-if="dataAsset">
                {{ dataAsset.asset_name }}
            </label>
        </div>
        <div class="col-md-6 d-md-flex justify-content-between py-2">
            <h6>
                Số người tham gia đấu giá: {{ countMemberJoin }}
            </h6>
            <h6>
                Số lượt trả giá: {{ pages.totalData }}
            </h6>
        </div>
        <div class="col-12">
            <div class="table-responsive tab-product-detail">
                <b-table responsive="md" :fields="bid_history.fields" :items="bid_history.items" tbody-tr-class="my-2">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(member_code)="row" >
                        <span :class="{ 'return_price': row.item.kick == 1 }">
                            {{ row.item.member_code }}
                        </span>
                    </template>
                    <template #cell(price)="row">
                        <span :class="{ 'return_price': row.item.withdraw_status == 1 || row.item.action == 2 || row.item.kick == 1 }" v-if="row.item.price">
                            {{ parseInt(row.item.price).toLocaleString("vi-VN") }} VNĐ
                        </span>

                        <span v-show="row.item.withdraw_status == 1 || row.item.action == 2" class="text-red">
                            Rút giá
                        </span>
                    </template>
                    <template #cell(time)="row">
                        <span :class="{ 'return_price': row.item.kick == 1 }">
                            {{ formateTime(row.item.time) }}
                        </span>
                        
                    </template>
                    <template #cell(status)="row">
                        <span class="highest justify-content-start"
                            v-if="row.item.winner_status == 1 ">
                            <img src="@/assets/icon/Ok.png" style="width: 20px;height: auto;" alt="">
                            Trúng đấu giá
                        </span>
                        <span class="lower justify-content-start" v-else>
                            <img src="@/assets/icon/close.png" style="width: 20px;height: auto;" alt="">
                            Không trúng đấu giá
                        </span>
                    </template>
                </b-table>
                <Pagination :page="pages" @set-page="setPage" />
            </div>
        </div>
    </div>
</template>
<script>
import Table from "../components/table.vue"
import Pagination from "../components/paginate.vue";

export default {
    components: {
        Table,
        Pagination
    },
    data() {
        return {

            items: [
                {
                    text: 'Trang chủ',
                    href: '/',
                },
                {
                    text: 'Quản lý tài khoản',
                    href: '/account'
                },
                {
                    text: 'Lịch sử trả giá',
                    active: true
                },
            ],
            bid_history: {
                fields: [
                    { key: 'index', label: 'Stt', thClass: 'text-center col-md-1', tdClass: 'text-center col-md-1' },
                    { key: 'member_code', label: 'Mã đấu giá', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                    { key: 'price', label: 'Giá đã trả (VNĐ)', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3 text-red' },
                    { key: 'time', label: 'Thời gian trả giá', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                    { key: 'status', label: 'Trạng thái', thClass: 'text-center col-md-2', tdClass: 'col-md-2' },
                ],
                items: [

                ],
            },
            countMemberJoin: 0,
            pages: {
                perPage: 10,
                currentPage: 1,
                totalData: 0,
                totalPage: 0,
                classPrev: 'd-block prev',
                classNext: 'd-block next',
            },
            dataAsset: null
        }
    },
    mounted() {
        this.getData()
        this.getAuctionHistory()
    },
    methods: {
        async getData() {
            await this.$axios.get('/asset/getAssetDetail', {
                params: {
                    asset_id: this.decode(this.$route.params.id)
                },
                headers: {
                    'x-access-token': '',
                }
            }).then((res) => {
                res.data.auction_fee = parseInt(res.data.auction_fee)
                res.data.price = parseInt(res.data.price)
                res.data.register_auction_fee = parseInt(res.data.register_auction_fee)
                res.data.step_price = parseInt(res.data.step_price)
                this.dataAsset = res.data
            })
        },
        async getAuctionHistory() {
            await this.$axios.get('/auction/getAuctionHistory', {
                params: {
                    asset_id: this.decode(this.$route.params.id),
                    page: this.pages.currentPage,
                    per_page: this.pages.perPage
                },
                headers: {
                    'x-access-token': localStorage.getItem('member')
                }
            }).then((res) => {
                this.countMemberJoin = res.data.count_member_join
                this.bid_history.items = res.data.re
                this.pages.totalData = res.data.pagination.total_element
                this.pages.totalPage = res.data.pagination.total_page
            })
        },
        setPage(val) {
            this.pages.currentPage = val;
            this.getAuctionHistory();
        },
    }

}
</script>
<style scoped lang="css">
.pagination {
    justify-content: space-between;
}

.return_price {
    text-decoration: line-through;
}

.tab-product-detail tr {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 53px;
}

.tab-product-detail thead tr {
    background-color: #E5E5E5;
    border-radius: 10px;
}

.tab-product-detail tbody tr {
    border: #ABABAB 1px solid;
    border-radius: 25px;
}

.tab-product-detail tr td {
    border-bottom-width: 0;
}

.payment-history {
    color: #515151;
}

.table td.col-md-2 {
    display: flex;
    align-items: center;
}

.payment-history .title-1 {
    font-family: Libre Bodoni;
    font-weight: 500;
    font-size: 24px;
    height: 30px;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
}

.payment-history .title-2 {
    font-family: Libre Bodoni;
    font-weight: 500;
    font-size: 24px;
    align-items: center;
    display: flex;
}

.payment-history span {
    font-size: 15px;
    font-family: Roboto;
    font-weight: 500;
}

@media (max-width:525px) {

    .table-responsive td.col-md-2 {
        font-size: 0.5rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
</style>