import { render, staticRenderFns } from "./paymentRequest.vue?vue&type=template&id=3662c31e&scoped=true"
import script from "./paymentRequest.vue?vue&type=script&lang=js"
export * from "./paymentRequest.vue?vue&type=script&lang=js"
import style0 from "./paymentRequest.vue?vue&type=style&index=0&id=3662c31e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3662c31e",
  null
  
)

export default component.exports