<template>
    <div class="auth">
        <b-modal id="edit_account" ref="editAccount" size="lg" centered hide-footer hide-header modal-class="modal-edit-account">
            <form action="" class="auth register" @submit.prevent="updateMember(formData)">
                <div class="row edit_account">
                    <h5 class="ml-1">Chỉnh sửa thông tin tài khoản</h5>
                    <div class="modal-contents">
                        <div class="col-12">
                            <div class="form-group d-flex">
                                <div class="col-5 d-none-mobie align-self-center">
                                    <label class="" v-if="formData.type == 1">Họ và tên </label>
                                    <label class="" v-else>Người đại diện theo pháp luật </label>
                                </div>
                                <div class="form-check flex-md-row align-items-center col-md-7 col-xs-12">
                                    <label class="reg-fn mb-2 d-sm-none" v-if="formData.type == 1">Họ và tên </label>
                                    <label class="reg-fn mb-2 d-sm-none" v-else>Người đại diện theo
                                        pháp luật </label>
                                    <input class="form-control" name="member_name" required placeholder="Họ - Tên đệm - Tên"
                                        v-if="formData.type == 1" v-model="formData.member_name" @input="inputTxt">
                                    <input class="form-control" value="" name="rep_name" required @input="inputTxt"
                                        placeholder="Họ - Tên đệm - Tên" v-else v-model="formData.rep_name">
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12" v-if="formData.type == 2">
                            <div class="form-group d-flex">
                                <div class="col-5 d-none-mobie align-self-center">
                                    <label class="">Chức vụ</label>
                                </div>
                                <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                    <label class="reg-fn mb-2 d-sm-none">Chức vụ</label>
                                    <input class="form-control" value="" name="position" required placeholder="Chức vụ"
                                        v-model="formData.position" @input="inputTxt">
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group d-flex">
                                <div class="col-5 d-none-mobie align-self-center text-justify">
                                    <label class="">Số điện thoại ( di động )</label>
                                </div>
                                <div class="form-check flex-md-row align-items-center col-md-7 col-xs-12">
                                    <label class="reg-fn mb-2 d-sm-none">
                                        Số điện thoại ( di động )
                                    </label>
                                    <input class="form-control format-number" required name="phone" maxlength="10"
                                        v-model="formData.phone" type="text"
                                        placeholder="Nhập số điện thoại di động" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group d-flex">
                                <div class="col-5 d-none-mobie align-self-center">
                                    <label class="">Địa chỉ email</label>
                                </div>
                                <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                    <label class="reg-fn mb-2 d-sm-none">Địa chỉ email</label>
                                    <input type="email" class="form-control" name="email" required
                                        placeholder="Nhập email liên hệ" disabled v-model="formData.email">
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group d-flex">
                                <div class="col-5 d-none-mobie align-self-center">
                                    <label class="">Ngày sinh</label>
                                </div>
                                <div
                                    class="form-check d-md-flex flex-wrap align-items-center col-md-7 col-xs-12">
                                    <label class="reg-fn mb-2 d-sm-none">Ngày sinh</label>
                                    <div class="col-12 p-0 p-0 d-flex" @change="checkBirthDay()">
                                        <select class="form-select col-4" name="day" v-model="birthday[0]" required>
                                            <option value="">Ngày</option>
                                            <option v-for="d in valueDay" :value="formatpreNumber(d)">
                                                {{ formatpreNumber(d) }}
                                            </option>
                                        </select>
                                        <select class="form-select col-4" name="month" required
                                            @change="__getDayOfMonth" v-model="birthday[1]">
                                            <option value="">Tháng</option>
                                            <option v-for="m in 12" :value="formatpreNumber(m)">{{ formatpreNumber(m) }}</option>
                                        </select>
                                        <select class="form-select col-4" name="year" v-model="birthday[2]" required>
                                            <option value="">Năm </option>
                                            <option v-for="year in years.slice().reverse()"
                                                :value="year">{{ year }}</option>
                                        </select>
                                    </div>
                                    <label class="col-12 mt-2 pl-2 text_red" v-if="duplicateBirthDay">
                                        {{ duplicateBirthDay }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group d-flex">
                                <div class="col-5 d-none-mobie align-self-center">
                                    <label class="">Giới tính</label>
                                </div>
                                <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                    <label class="reg-fn mb-2 d-sm-none">Giới tính</label>
                                    <b-form-radio-group class="gender d-flex col-12">
                                        <b-form-radio value="0" class="btn-radio col-4" v-model="formData.gender"
                                            :class="{ 'active': formData.gender == 0 }">
                                            <div class="d-flex align-items-center">
                                                <img src="@/assets/front-end/img/Ellipse.png" style="width: 15px;height: 15px;"
                                                    alt="">
                                                <label class="">Nam</label>
                                            </div>
                                        </b-form-radio>
                                        <b-form-radio value="1" class="btn-radio col-4" v-model="formData.gender"
                                            :class="{ 'active': formData.gender == 1 }">
                                            <div class="d-flex align-items-center">
                                                <img src="@/assets/front-end/img/Ellipse.png" style="width: 15px;height: 15px;"
                                                    alt="">
                                                <label class="">Nữ</label>
                                            </div>
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group d-flex">
                                <div class="col-5 d-none-mobie align-self-center">
                                    <label class="">Địa chỉ</label>
                                </div>
                                <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12 row ml-0">
                                    <label class="reg-fn mb-2 d-sm-none">Địa chỉ</label>
                                    <div class="col-12 p-0 p-0 d-flex flex-wrap form-group">
                                        <select class="form-select col-lg-6" name="city" required
                                            @input="__changeCity" v-model="formData.province_id">
                                            <option value="0">Tỉnh/Thành phố</option>
                                            <option v-for="item in dataCity"
                                                :value="item.code">{{ item.name }}
                                            </option>
                                        </select>
                                        <select class="form-select col-lg-6" name="district" required
                                            @input="__changeDistrict" v-model="formData.district_id">
                                            <option value="0">Quận/Huyện</option>
                                            <option v-for="district in dataDistricts"
                                                :value="district.code">
                                                {{ district.name }}
                                            </option>
                                        </select>
                                        <select class="form-select col-lg-6" name="ward" required
                                            v-model="formData.ward_id">
                                            <option value="0" selected>Xã /Phường</option>
                                            <option v-for="ward in dataWard" :value="ward.code">
                                                {{ ward.name }}
                                            </option>
                                        </select>
                                    </div>
                                    
                                    <div class="col-12 p-0 p-0">
                                        <input class="form-control" value=""
                                            required placeholder="Địa chỉ chi tiết" name="member_address"
                                            v-model="formData.member_address" @input="inputTxt">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group d-flex">
                                <div class="col-5 d-none-mobie align-self-center">
                                    <label class="">Số CMT/ Thẻ căn cước /Hộ chiếu</label>
                                </div>
                                <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                    <label class="reg-fn mb-2 d-sm-none">Số CMT/ Thẻ căn cước /Hộ
                                        chiếu</label>
                                    <input class="form-control format-number" v-model="formData.id_number" @input="__checkNumber"
                                        name="id_number" type="text" required placeholder="Nhập số"
                                        @change="checkDuplicateId">
                                    <label class="col-12 mt-2 pl-2 text_red" v-if="duplicateId">
                                        {{ duplicateId }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group d-flex">
                                <div class="col-5 d-none-mobie align-self-center">
                                    <label class="">Ngày cấp, nơi cấp CMT / Thẻ căn cước / Hộ chiếu
                                    </label>
                                </div>
                                <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                    <label class="reg-fn mb-2 d-sm-none">
                                        Ngày cấp, nơi cấp CMT / Thẻ căn cước / Hộ chiếu </label>
                                    <div class="col-12 p-0 d-flex">
                                        <div class="col-6 pl-0 pr-2">
                                            <b-form-datepicker class="mb-2 text-center set-color" :max="max" locale="vi" start-weekday=1
                                                placeholder="dd/mm/yyyy" v-model="formData.id_date"
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                            </b-form-datepicker>
                                        </div>
                                        <div class="col-6 pr-0 pl-2">
                                            <input class="form-control placeholder-center" value="" name="id_place" required
                                                v-model="formData.id_place" placeholder="Nơi cấp CMT, CC, HC" @input="inputTxt">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group d-flex align-items-center">
                                <div class="col-5 d-none-mobie align-self-center">
                                    <label class="">Ảnh 2 mặt CMT / Thẻ căn cước / Hộ Chiếu </label>
                                </div>
                                <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                    <label class="reg-fn mb-2 d-sm-none">
                                        Ảnh 2 mặt CMT / Thẻ căn cước / Hộ Chiếu</label>
                                    <div class="col-12 p-0 d-flex">
                                        <div class="col-5 text-center">
                                            <label class="d-flex justify-content-center">
                                                Mặt trước
                                            </label>
                                            <input type="file" name="id_front_face" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFileFront"
                                                class="image_input_review uploadFront" hidden @change="__uploadFile" id="id_front_face">
                                            <img class="review-img" onclick="$('#id_front_face').click()" @error="handleError"
                                                :src="this.$storage + formData.id_front_face" alt="" ref="id_front_face" >
                                        </div>                               
                                        <div class="col-2"></div>
                                        <div class="col-5 text-center">
                                            <label class="d-flex justify-content-center">
                                                Mặt sau</label>
                                            <input type="file" name="id_back_side" id="back_id_1"
                                                class="image_input_review uploadBack" hidden ref="inputFile"
                                                @change="__uploadFile" accept="image/png, image/gif, image/jpeg, image/jpg">
                                            <img class="review-img" ref="id_back_side" @error="handleError"
                                                :src="this.$storage + formData.id_back_side" onclick="$('#back_id_1').click()" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- -----------REGISTER ORGANIZATION---------- -->
                        <div v-if="formData.type == 2" class="pl-0">
                            <div class="col-12">
                                <div class="form-group d-flex">
                                    <div class="col-5 d-none-mobie align-self-center">
                                        <label class="">Tên tổ chức / công ty / doanh nghiệp</label>
                                    </div>
                                    <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                        <label class="reg-fn mb-2 d-sm-none">Nhập tên</label>
                                        <input class="form-control" value="" name="member_name" required placeholder="Nhập tên"
                                        v-model="formData.member_name" @input="inputTxt">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group d-flex">
                                    <div class="col-5 d-none-mobie align-self-center">
                                        <label class="">Mã số doanh nghiệp / mã số thuế</label>
                                    </div>
                                    <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                        <label class="reg-fn mb-2 d-sm-none">
                                            Mã số doanh nghiệp / mã số thuế
                                        </label>
                                        <input class="form-control format-number" v-model="formData.tax_code" name="tax_code" required
                                            placeholder="Mã số thuế" @input="inputTxt"
                                            @change="checkDuplicateTax">
                                        <label class="col-12 mt-2 pl-2 text_red" v-if="duplicateTax">
                                            {{ duplicateTax }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group d-flex">
                                    <div class="col-5 d-none-mobie align-self-center">
                                        <label class="">Ngày cấp mã số thuế</label>
                                    </div>
                                    <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                        <label class="reg-fn mb-2 d-sm-none">Ngày cấp mã số
                                            thuế</label>
                                        <b-form-datepicker class="mb-2 set-color" :max="max" locale="vi" start-weekday=1
                                            placeholder="dd/mm/yyyy" v-model="formData.tax_date"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                        </b-form-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group d-flex">
                                    <div class="col-5 d-none-mobie align-self-center">
                                        <label class="">Nơi cấp</label>
                                    </div>
                                    <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                        <label class="reg-fn mb-2 d-sm-none">Nơi cấp</label>
                                        <input class="form-control" value="" name="tax-place" required
                                            placeholder="Nơi cấp mã số thuế / mã số doanh nghiệp"
                                            v-model="formData.tax_place" @input="inputTxt">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group d-flex">
                                    <div class="col-5 d-none-mobie align-self-center">
                                        <label class="">Địa chỉ tổ chức</label>
                                    </div>
                                    <div class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                        <label class="reg-fn mb-2 d-sm-none">Địa chỉ tổ chức</label>
                                        <input class="form-control" value="" name="rep_address" required @input="inputTxt"
                                            placeholder="Địa chỉ doanh nghiệp" v-model="formData.rep_address">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group d-flex">
                                    <div class="col-5 d-none-mobie align-self-center">
                                        <label class="">Tải lên giấy chứng nhận đăng ký kinh doanh
                                            ( Định dạng .pdf )</label>
                                    </div>
                                    <div class="form-check align-items-center col-md-7 col-xs-12">
                                        <label class="reg-fn mb-2 d-sm-none">Tải lên giấy chứng nhận
                                            đăng ký kinh doanh ( Định dạng .pdf )</label>
                                        <b-link v-if="formData.business_registration" class="business" :href="this.$storage + formData.business_registration" target="_blank">Giấy chứng nhận đăng ký kinh doanh</b-link>
                                        <input type="file" accept="application/pdf" @change="__uploadFile"
                                            class="form-control" name="business_registration"
                                            placeholder="Giấy chứng nhận đăng ký kinh doanh">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group d-flex">
                                    <div class="col-5 d-none-mobie align-self-center">
                                        <label class="">Tải lên giấy tờ khác <br>
                                            ( Định dạng .pdf )</label>
                                    </div>
                                    <div class="form-check d-md-flex flex-wrap align-items-center col-md-7 col-xs-12">
                                        <label class="reg-fn mb-2 d-sm-none">Tải lên giấy tờ khác
                                                <br> ( Định dạng .pdf )</label>
                                        <b-link class="business" v-for="(file, index) in formData.file"
                                        :href="this.$storage + file.url" :key="index"
                                        target="_blank">{{ file.file_name }}</b-link>
                                        <input type="file" accept="application/pdf" multiple 
                                            class="form-control" name="file_path" @change="__uploadFile"
                                            placeholder="Tải lên giấy tờ khác">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="text-sm-right" style="text-align: center;">
                        <b-button class="mt-3 btnn btn-outline-primary mx-2" block @click="hideModal()">Hủy bỏ</b-button>
                        <b-button class="mt-3 btnn btn-primary" type="submit" >Lưu chỉnh sửa</b-button>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
import authJS from "@/pages/web-view/auth/auth"
import Vue from "vue"
Vue.mixin(authJS)
export default {
    props:['formData'],
    data() {
        return {
            checkForm: '',
            iconStt: true,
            iconSttRepass: true,
            rePassword: '',
            dataCity: [],
            dataDistricts: [],
            dataWard: [],
            max: new Date(),
            valueDay: 31,
            valueMonth: '',
            valueYear: new Date().getFullYear(),
            years: [],
            type: 1,
            birthday:['','',''],
            duplicateBirthDay:'',
            duplicateTax: '',
            duplicateId: '',
        }
    },

    mounted() {
        this.$axios.get('/common/getListProvince')
            .then(res => {
                this.dataCity = res.data.province
            })
            .catch(error => {
                console.error(error);
                
            });
        this.generateYearList();
    },
    
    computed:{
        
    },
    methods: {
        inputTxt() {
            let name = event.target.name
            this.formData[name] = this.formData[name].replace(/[<>\[\]]/g, '')
        },
        async getData(){
            this.$axios.get('/common/getListProvince')
            .then(res => {
                this.dataCity = res.data.province
            })
            .catch(error => {
                console.error(error);

            });
            this.dataDistricts = await this.callApiDistrict(this.formData.province_id)
            this.dataWard = await this.callApiWard(this.formData.district_id)
        },
        hideModal() {
            this.$refs.editAccount.hide()
        },
        show() {
            this.$refs.editAccount.show()
            this.getBirthday()
            this.getData()
        },
        checkBirthDay() {
            this.formData.birthday = this.birthday[2] + "-" + this.birthday[1] + '-' + this.birthday[0]
            if (this.birthday[1] && this.birthday[2] && this.birthday[0]) {
                if (new Date(this.formData.birthday) >= new Date()) {
                    this.duplicateBirthDay = 'Ngày sinh không hợp lệ'
                    this.birthday = ['','','']
                }
                else {
                    this.duplicateBirthDay = ''
                }
            }
        },
        getBirthday() {
            let data = this.formateTime(this.formData.birthday, 'DD/MM/YYYY')
            this.birthday = data.split('/')
        },
        async checkDuplicateId() {
            await this.$axios.post('/common/checkDuplicateId', {
                id_number: this.formData.id_number
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                this.duplicateId = ''
            }).catch(err => {
                this.duplicateId = err.response.data.error
            })
        },
        async checkDuplicateTax() {
            await this.$axios.post('/common/checkDuplicateTax', {
                tax_code: this.formData.tax_code
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                this.duplicateTax = ''
            }).catch(err => {
                this.duplicateTax = err.response.data.error
            })
        },
    }
}
</script>
<style lang="css" scoped>
    .modal-contents{
        height: 627px;
        overflow: auto;
        border-radius: unset;
    }
    .btnn.btn-primary:hover, .btnn.btn-primary:active{
        color: #ffffff;
        background-color: #EE3434;
        border: #EE3434 1px solid;
    }
    .btnn.btn-outline-primary:active, .btnn.btn-outline-primary:focus-visible{
        color: #EE3434;
        background-color: #FFFFFF;
        border: #EE3434 1px solid;
    }
    .auth .b-form-datepicker .form-control, .auth .form-group label{
        color: #515151 !important;
    }

    .auth .form-control{
        background-color:  #E8E8E8;   
        color: #8d8d8d; 
    }
    .auth .form-select{
        filter: unset;
        color: #515151;
    }
    .gender img{
        width: 15px;
        height: 15px;
        border: 1px solid #515151;
        border-radius: 50px;
    }
    .gender .active img{
        filter: brightness(0.3);
    }
    .auth input.form-control::placeholder,input {
        color: #8d8d8d;
    }
    .form-control{
        height: 32px;
    }
    .toggel-password {
        top: 7px;
    }
    button.btnn {
        border-radius: 12px;
    }

    .business {
        position: relative;
        display: block;
        color: #515151;
        font-size: 14px;
        margin-bottom: 10px;
        padding-left: 30px;
    }

    .business::before {
        position: absolute;
        content: '';
        background-image: url('@/assets/icon/Note.png');
        width: 25px;
        height: 25px;
        left: 0;
    }
</style>
<style lang="css">
    .modal-edit-account .modal-body{
        height: 743px;
    }
    .toggel-password:hover {
        color: #515151;
    }
    .set-color .form-control {
        color: #8d8d8d !important;
    }
    @media (max-width:332px) {
        .modal-edit-account .modal-body{
            height: auto;
        }
    }
</style>