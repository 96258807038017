import { render, staticRenderFns } from "./product_auction.vue?vue&type=template&id=2a51c54e&scoped=true"
import script from "./product_auction.vue?vue&type=script&lang=js"
export * from "./product_auction.vue?vue&type=script&lang=js"
import style0 from "./product_auction.vue?vue&type=style&index=0&id=2a51c54e&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a51c54e",
  null
  
)

export default component.exports