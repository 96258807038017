<template>
    <div id="home">
        <div class="bg-transparent">
            <div class="banner-main first">
                <div class="banner-main second">
                    <div class="area-btn-seller container">
                        <div class="btn-login d-none-mobie" v-if="!isLoggedIn">
                            <b-link to="/login">Đăng nhập</b-link> /
                            <b-link to="/register">Đăng ký</b-link>
                            <b-link to="/countdown">
                                <img src="@/assets/front-end/icon/Time.png" style="width: 30px; height: 30px;" alt="">
                            </b-link>
                        </div>
                    </div>
                    <div style="text-align: center; color: #D6B877; font-family: 'Libre Bodoni', serif;">
                        <img class="home_logo" src="@/assets/front-end/img/Group 467.png" alt="">
                    </div>
                    <div class="quote">
                        <div>
                            Lấy chữ tín làm đầu, sự hài lòng, tín nhiệm của quý khách hàng
                        </div>
                        <div>
                            chính là sự thành công của chúng tôi.
                        </div>
                    </div>
                    <div class="group-btn">
                        <div class="col-12 d-md-flex justify-content-center">
                            <div class="col-xs-12 flex-center mr-md-4 mb-3">
                                <a @click="$router.push('product-auction')" class="btnn btn-gold " 
                                    href="#" style="width: 267px; height: 44px;">
                                    <img class="mx-2" style="width: 32px; height: 32px;"
                                        src="@/assets/front-end/icon/Auction.png" alt="">
                                    <div style="min-width: 205px; text-align: center;">Tài sản đấu giá</div>
                                </a>
                            </div>
                            <div class="col-xs-12 flex-center">
                                <b-link class="btnn btn-gold" to="/account/auction" style="width: 267px; height: 44px;">
                                    <img class="mx-2" style="width: 32px; height: 32px;"
                                        src="@/assets/front-end/icon/Online.png" alt="">
                                    <div style="min-width: 205px; text-align: center;">Đấu giá trực tuyến</div>
                                </b-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container overflow-hidden">
            <div class="">
                <div class="header-home my-3 w-100">
                    Công ty Đấu giá hợp danh DGV xin trân trọng cảm ơn sự quan tâm, tín nhiệm và hợp tác của quý khách hàng
                    đối
                    với các dịch vụ của công ty.
                </div>
                <div class="my-3 px-3 flex-between content-home">
                    <div class="col-md-6 col-xs-12 px-3 scroll-mobie">Công ty Đấu giá hợp danh DGV được thành lập với đội
                        ngũ
                        cán bộ quản lý có nhiều năm kinh nghiệm trong lĩnh vực tổ chức hoạt động đấu giá tài sản, tư vấn
                        pháp
                        luật và cung cấp các dịch vụ làm thủ tục chuyển quyền sở hữu, quyền sử dụng tài sản; quản lý tài sản
                        và
                        các dịch vụ khác liên quan đến tài sản đấu giá.
                    </div>
                    <div class="col-md-6 col-xs-12 px-3 scroll-mobie">Chúng tôi cam kết mang lại cho khách hàng những dịch
                        vụ
                        tốt nhất, thực hiện công việc một cách hiệu quả, minh bạch, đúng pháp luật và bảo vệ lợi ích của các
                        bên
                        tham gia. Chúng tôi luôn lấy chữ tín làm đầu, sự hài lòng, tín nhiệm của quý khách hàng chính là sự
                        thành công và là tiền đề phát triển của công ty
                    </div>
                </div>
            </div>
            <auctionInfomation />
            <homeNew />
            <div class="join-live">
                <div class="header-home mt-2 col-12">
                    <div>
                        Trải nghiệm đấu giá đa dạng, kết hợp online - offline thuận tiện, hiệu quả
                    </div>
                </div>
                <div class="my-2 mx-4 flex-between content-home">
                    <div class="col-12">
                        Công ty Đấu giá hợp danh DGV để hòa chung vào nhịp đập của thời đại, lắng nghe góp ý của những nhà
                        đầu tư và quý khách hàng đã phát triển và tích hợp chuyển đổi số vào trong lĩnh vực đấu giá tại Việt
                        Nam.
                        Tạo ra trải nghiệm đấu giá kết hợp giữa trực tiếp và trực tuyến, thuận tiện, hiệu quả, giảm thời
                        gian và khoảng cách địa lý cho nhà đầu tư và đối tác.
                    </div>
                </div>
                <div class="my-3 flex-center">
                    <div class="col-lg-6 col-md-12 col-xs-10 text-center">
                        <b-link to="/account/auction" class="col-12 text-center btn-outline d-block">
                            <img class="mr-2" src="@/assets/front-end/icon/Online.png" alt="">
                            Tham gia đấu giá trực tuyến DGV
                        </b-link>
                    </div>
                </div>
            </div>
            <div class="home-cate">
            <div class="header-home mt-2 w-100">
                <div>
                    Sản phẩm, danh mục đấu giá đa dạng
                </div>
            </div>
            <div class="col-12 my-2 mx-md-4 content-home">
                <div class="col-12 px-2">
                    Công ty Đấu giá hợp danh DGV có nhiều năm kinh nghiệm trong lĩnh vực tổ chức hoạt động đấu giá tài
                    sản, tư vấn pháp luật và cung cấp các dịch vụ làm thủ tục chuyển quyền sở hữu, quyền sử dụng tài sản;
                    quản lý tài sản và các dịch vụ khác liên quan đến tài sản đấu giá. Trong lĩnh vực đấu giá, chúng tôi đẩy
                    mạnh đa dạng những nguồn sản phẩm và danh mục đấu giá cả về tài sản công cũng như tài sản cá nhân, đảm
                    bảo uy tín, đa dạng, thuận tiện cho người có tài sản và người đấu giá
                </div>
            </div>
            <div class="col-12 d-md-flex mt-3">
                <div class="col-md-6 p-1">
                    <img src="@/assets/front-end/img/group-trucks-parked-row.png" alt="">
                </div>
                <div class="col-xs-12 d-none-mobie p-1 pr-2">
                    <img src="@/assets/front-end/img/mumbai-skyline-skyscrapers-construction.png" alt="">
                </div>
            </div>
            <div class="col-12 d-md-none">
                <div class="col-xs-12 p-1">
                    <img src="@/assets/front-end/img/mumbai-skyline-skyscrapers-construction.png" alt="">
                </div>
            </div>
            <div class="col-12 d-md-flex">
                <div class="col-md-6 p-1">
                    <img src="@/assets/front-end/img/land-plot-with-nature-landscape-location-pin.png" alt="">
                </div>
                <div class="d-flex">
                    <div class="col-6 p-1">
                        <img src="@/assets/front-end/img/photo-automobile-production-line-welding-car-body.png" alt="">
                    </div>
                    <div class="col-6 p-1">
                        <img src="@/assets/front-end/img/wooden-gavel.png" alt="">
                    </div>
                </div>
            </div>
        </div>
            <!-- <homeCategory /> -->
            <div class="last-content">
                <div class="col-12 m-md-2">
                    <div class="category_content p-md-4">
                        <div class="title col-md-6 col-xs-12 px-xs-2">TRỞ THÀNH ĐỐI TÁC CỦA DGV</div>
                        <div class="col-12 flex-end">
                            <div class="col-md-6 col-xs-12 pr-md-4 mt-3">
                                <div class="content">
                                    Trở thành đối tác của DGV <br>
                                    Chúng tôi cam kết mang lại cho khách hàng những dịch vụ tốt nhất, thực hiện công việc
                                    một cách hiệu quả, minh bạch, đúng pháp luật và bảo vệ lợi ích của các bên tham gia.
                                    Chúng tôi luôn lấy chữ tín làm đầu; sự hài lòng, tín nhiệm của quý khách
                                    hàng chính là sự thành công và là tiền đề phát triển của công ty
                                </div>
                                <div class="d-xs-flex justify-content-center">
                                    <b-link to="/contact" class="btn">Liên hệ bán tài sản</b-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Phone :phone="infomation.phone"/>
        <buttonMessage />
        <!-- <buttonZalo /> -->
    </div>
</template>
<script>
import auctionInfomation from "./auctionInfomation.vue";
import homeNew from "./homeNew.vue";
import homeCategory from "./homeCategory.vue";
import Phone from "../components/buttonPhone.vue"
import buttonZalo from "../components/buttonZalo.vue";
import buttonMessage from "../components/buttonMessage.vue";
import { mapGetters } from 'vuex';
export default {
    components: {
        auctionInfomation,
        homeNew,
        homeCategory,
        Phone,
        buttonMessage,
        buttonZalo
    },
    data() {
        return {
            infomation: {}
        }
    },
    computed: {
        ...mapGetters(['isLoggedIn'])
    },
    created(){
        this.infomation = this.getInformation()
    }
}
</script>
