<template>
   <div class="text-center auctioneer">
       <img class="logo" src="@/assets/images/Logo.png" alt="">
       <p class="quote">Lấy chữ tín làm đầu, sự hài lòng, tín nhiệm của quý khách hàng <br/> chính là sự thành công của chúng tôi.</p>
       <b-row style="justify-content: space-around; max-width: 1200px; margin: 0 auto;">
            <h5>Quản lý của đấu giá viên</h5>
            <b-col cols="md-4" class="item">
                <b-link to="/auctioneer/asset">
                    <img src="@/assets/admin/icon/Mortgage.png" alt="">
                    <p>Tài sản đấu giá </p>
                </b-link>
            </b-col>
            <b-col cols="md-4" class="item">
                <b-link to="/auctioneer/auction">
                    <img src="@/assets/admin/icon/auctioneer.png" alt="">
                    <p>Cuộc đấu giá </p>
                </b-link>
            </b-col>
            <b-col cols="md-4" class="item">
                <b-link to="/auctioneer/auction">
                    <img src="@/assets/admin/icon/Room.png" alt="">
                    <p>Phòng đấu giá </p>
                </b-link>
            </b-col>
       </b-row>
    </div>
</template>
<script>

export default {

}
</script>
<style lang="css" scoped>
    .auctioneer {
        font-family: Roboto;
        height: 100%;
        background-image: url('@/assets/front-end/img/background.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 868px;
    }

    .logo {
        margin: 47px 0 20px;
    }

    .quote {
        font-size: 19px;
        font-weight: 500;
        color: #9E9E9E;
        line-height: 22.3px;
    }

    h5 {
        color: #D6B877;
        font-family: Libre Bodoni;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        margin: 30px 0 20px;
    }

    .item a{
        display: block;
        background-color: #D6B877;
        border-radius: 25px;
        max-width: 258.84px;
        max-height: 112px;
        padding: 15px 0;
        font-family: Libre Bodoni;
        font-size: 23.31px;
        color: #333333;
        margin: 0 auto;
    }

    @media (max-width: 768px) {
        .item a {
            margin-bottom: 15px;
        }
    }
</style>
