<template>
    <div>
        <b-container class="auction">
            <div class="d-flex justify-content-between">
                <b-breadcrumb :items="items"></b-breadcrumb>
            </div>
            <b-row>
                <h5 class="title" v-if="!showAuction">Cuộc đấu giá: <span>Chi tiết cuộc đấu giá</span></h5>
                <h5 class="title" v-else>Cuộc đấu giá: <span>{{ title }}</span></h5>
            </b-row>

            <b-row>
                <b-col cols="xl-3" class="col-100">
                    <b-form class="form-search" @submit.prevent="setPage(1)"
                        v-if="$route.path == '/auction'">
                        <b-form-group id="input-group-1" label-for="input-1">
                            <b-form-input id="input-1" placeholder="Tìm kiếm cuộc đấu giá" class="input-search"
                                v-model="paramsAuction.keyword"></b-form-input>
                            <img class="search_ico" src="@/assets/icon/Search.png" alt=""
                                @click="setPage(1)">
                        </b-form-group>
                    </b-form>
                    <b-form class="form-search" v-else>
                        <b-form-group id="input-group-1" label-for="input-1">
                            <b-form-input id="input-1" placeholder="Tìm kiếm cuộc đấu giá" required class="input-search"
                                v-model="params.asset_name" disabled></b-form-input>
                            <img class="search_ico" src="@/assets/icon/Search.png" alt="">
                        </b-form-group>
                    </b-form>
                    <div v-if="$route.path == '/auction'">
                        <ul class="auction-list">
                            <li class="auction-list-item"><b-link href="" @click="getDataAuction('')"
                                    :class="{ active: paramsAuction.auction_type == '' }">Danh mục đấu giá</b-link></li>
                            <li class="auction-list-item"><b-link href="" @click="getDataAuction('0')"
                                    :class="{ active: paramsAuction.auction_type == '0' }">Cuộc đấu giá sắp diễn ra
                                </b-link></li>
                            <li class="auction-list-item"><b-link href="" @click="getDataAuction('1')"
                                    :class="{ active: paramsAuction.auction_type == '1' }">Cuộc đấu giá đang diễn
                                    ra</b-link></li>
                            <li class="auction-list-item"><b-link href="" @click="getDataAuction('2,3')"
                                    :class="{ active: paramsAuction.auction_type == '2,3' }">Cuộc đấu giá đã kết
                                    thúc</b-link></li>
                        </ul>
                    </div>
                    <div class="cate-img">
                        <img class="img-cate" src="@/assets/images/auction_img.png" alt="">
                        <p class="cate-name">{{ cate_name }}</p>
                    </div>
                    <div class="filter" v-if="$route.path == '/auction/list_asset'">
                        <h6>Lọc tài sản theo giá </h6>
                        <b-form @submit="filterAsset">
                            <b-form-group id="input-group-1" label="Từ:" label-for="input-1">
                                <b-form-input id="input-1" placeholder="200.000.000"
                                    v-model="params.from_price"></b-form-input>
                            </b-form-group>

                            <b-form-group id="input-group-2" label="Đến:" label-for="input-2">
                                <b-form-input id="input-2" placeholder="50.000.000.000"
                                    v-model="params.to_price"></b-form-input>
                            </b-form-group>
                            <b-button type="submit" class="btn-filter">Lọc theo giá</b-button>
                        </b-form>
                    </div>
                    <div class="show-news d-flex d-xl-block flex-wrap">
                        <h5 class="col-12">Tin tức và thông báo mới</h5>
                        <div class="mb-2 p-0 col-xxl-12 col-md-6"  
                        v-for="(item, index) in listNews.result" :key="index">
                            <cardNews :item="item"></cardNews>
                        </div>
                    </div>
                </b-col>

                <b-col cols="xl-9" class="w-100-768">
                    <div class="col-12 flex-between align-items-center mb-3 p-1">
                        <p class="total" v-if="$route.path == '/auction'">Số kết quả phù hợp: 
                            <span v-if="listAuction.pagination">{{ listAuction.pagination.total_element }}</span>
                        </p>
                    </div>
                    <div class="justify-content-center">
                        <b-row v-if="$route.path == '/auction'">
                            <b-col cols="md-6 col-sm-6 col-lg-4" v-for="(auction_item, index) in listAuction.re" :key="index">
                                <div class="auction-item" @click="routeAsset(auction_item)">
                                    <img class="auction-item_img" :src="$storage+auction_item.thumbnail" alt="" @error="handleError">
                                    <img class="auction-item_ico" src="@/assets/icon/Auction2.png" alt=""
                                        v-show="auction_item.auction_status_id == 1">
                                    <img class="auction-item_ico" src="@/assets/icon/golden sold out.png" alt=""
                                        v-show="auction_item.auction_status_id == 2 || auction_item.auction_status_id == 3">
                                    <p class="auction-item_txt">{{ auction_item.auction_name }}</p>
                                    <p class="auction-item_time">Thời gian đấu giá : <span
                                            class="auction-item_time--highlight">{{ formateTime(auction_item.start_time) }}</span></p>
                                </div>
                            </b-col>
                            <paginate :page="pageAuction" @set-page="setPage"/>
                        </b-row>
                        <router-view :dataAuction="auctionSelected" :params="this.params"></router-view>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import cardNews from '../components/card-news.vue';
import paginate from '../components/paginate.vue';
import ServerTime from '../components/serverTime.vue';
export default {
    components:{
        cardNews,
        paginate,
        ServerTime,
    },
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    to: '/',
                },
                {
                    text: 'Cuộc đấu giá',
                    active: true
                },
            ],
            
            listNews: {},
            status: 0,
            active_el: 1,
            title: "Tất cả",
            showAuction: true,
            id: '',
            statusAuction: '',
            auctionSelected:{},
            params: {
                asset_name: '',
                asset_code: '',
                category_id: '',
                owner: '',
                to_price: '',
                from_price: '',
                from_step_price: '',
                to_step_price: '',
                winner: '',
                status: '',
                description: '',
                page: 1,
                per_page: 10,
                order: 'latest',
                auction_id: "",
            },
            paramsAuction: {
                page: 1,
                per_page: 15,
                keyword: '',
                auction_type: this.$route.query.type || '',
            },
            paramsNews:{
                page: 1,
                per_page: 2,
            },
            pageAuction: {
                perPage: 5,
                totalPage: 0,
                totalData: 0,
                currentPage: 1,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
            listAuction: {}
        }
    },
    created() {
        const currentPath = this.$route.path;
        if (currentPath.includes('auction_asset')) {
            this.showAuction = false
        }
        this.getListAssetForMember(this.params);
        if (this.$route.query) {
            this.getListAuctionForMember(this.paramsAuction)
        }
        this.getListNews(this.paramsNews)
    },
    computed: {
        cate_name() {
            if (this.paramsAuction.auction_type == '0') {
                return "Cuộc đấu giá sắp diễn ra"
            } else if (this.paramsAuction.auction_type == '1') {
                return "Cuộc đấu giá đang diễn ra"
            } else if (this.paramsAuction.auction_type == '2,3') {
                return "Cuộc đấu giá đã kết thúc"
            } else {
                return "Cuộc đấu giá"
            }
        }
    },
    watch: {
        "$route.query": function(url) {
            if (this.$route.path == '/auction') {
                this.paramsAuction.auction_type = url.type
                this.getListAuctionForMember(this.paramsAuction)
            }
        }
    },
    methods: {
        getDataAuction(status) {
            this.paramsAuction.page = 1
            this.$router.push({
                path:"/auction",
                query: {
                    type: status,
                },
            })
            this.paramsAuction.auction_type = status
            this.getListAuctionForMember(this.paramsAuction)
        },
        filterAsset() {
            this.$router.push({
                path: "/auction/list_asset",
                query: {
                    auction_id: this.params.auction_id,
                    type: this.statusAuction,
                    to_price: this.params.to_price,
                    from_price: this.params.from_price,
                    asset_name: this.params.asset_name
                },
            })
        },
        routeAsset(param) {
            this.$router.push({
                path: "/auction/list_asset/"+this.encode(param.auction_id),
            })
        },
        setPage(val) {
            this.paramsAuction.page = val;
            this.getListAuctionForMember(this.paramsAuction);
        },
    },
}
</script>

<style lang="css" scoped>
.auction-list {
    padding: 0;
}

.auction-list li {
    padding-left: 55px;
}

.auction-list li:first-child {
    padding-left: 30px;
}

.auction-list li a {
    color: #515151;
    font-size: 18px;
    line-height: 32.4px;
    font-weight: 500;
    text-decoration: none;
    font-family: Roboto;
}

.auction-list li a.active {
    font-weight: 700;
    color: #EE3439;
    position: relative;
}

.auction-list li:first-child a {
    font-family: Libre Bodoni;
    font-weight: 700;
}

.auction-list li a.active::before {
    content: "";
    position: absolute;
    background: url('@/assets/icon/Polygon\ 2.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    left: -26px;
    top: 2px;
}

.auction-item {
    display: block;
    width: 100%;
    background: #F8F8F8;
    border: 1px solid #D8D8D8;
    border-radius: 14px;
    font-size: 14.05px;
    text-decoration: none;
    color: #515151;
    font-family: Roboto;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
}

.auction-item_img {
    width: 100%;
    height: 205px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    object-fit: cover;
}

.auction-item_ico {
    position: absolute;
    top: 2%;
    left: 3%
}

.auction-item_txt {
    line-height: 25.29px;
    padding: 5px 7px 3px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 58px;
}

.auction-item_time {
    color: #929292;
    text-align: center;
    position: relative;
    padding: 0 8px 14px;
    margin: 0;
}

.auction-item_time--highlight {
    color: #EE3439;
    display: block;
    font-size: 16px;
    font-weight: 600;
}

.auction-item_time::before {
    content: "";
    background-image: url('@/assets/icon/Auction1.png');
    position: absolute;
    width: 44px;
    height: 44px;
    left: 5px;
    background-repeat: no-repeat;
}
@media (min-width: 1200px) {
    .col-xxl-12{
        flex: 0 0 100%;
        width: 100% !important;
        max-width: 100%;
    }
}
@media (max-width: 1200px) {
    .auction-list li {
        padding-left: 20px;
    }
    

}

@media (max-width: 1024px) {

    .cate-name {
        font-size: 24px;
    }

    .auction-list li a {
        font-size: 16px;
    }

    .auction-item_time {
        padding-left: 44px;
    }
}

@media (max-width: 768px) {
    .w-100-768 {
        width: 100%;
    }

    .cate-name {
        font-size: 30px;
        width: 40%;
    }

    .auction-list {
        margin-top: 25px;
    }

    .auction-list li a {
        font-size: 18px;
    }

    .auction-list li {
        padding-left: 50px;
    }

    .auction-item_time {
        padding-left: 44px;
    }
}

@media (max-width: 425px) {
    .cate-name {
        font-size: 18px;
        width: 50%;
    }

    .auction-item_time {
        padding-left: 0;
    }
}</style>