import { render, staticRenderFns } from "./news-detail.vue?vue&type=template&id=23f1de86&scoped=true"
import script from "./news-detail.vue?vue&type=script&lang=js"
export * from "./news-detail.vue?vue&type=script&lang=js"
import style0 from "./news-detail.vue?vue&type=style&index=0&id=23f1de86&prod&lang=css&scoped=true"
import style1 from "./news-detail.vue?vue&type=style&index=1&id=23f1de86&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f1de86",
  null
  
)

export default component.exports