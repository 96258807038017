<template>
    <div>
      <b-container class="auction_detail">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <b-row>
          <h5 class="title">{{ dataAuctions.asset_name }}</h5>
          <b-col cols="md-8">
            <h5 class="noti">THÔNG BÁO ĐẤU GIÁ</h5>
            <p class="info" v-html="dataAuctions.auction_noti"></p>
          </b-col>
          <b-col>
            <div class="more-info">
              <h5 class="more-info__ttl">Tài liệu liên quan</h5>
              <ul class="list-info">
                <li v-for="file in dataAuctions.file"><b-link :href="$storage + file.url" target="_blank" style="color: #515151;">{{ file.file_name }}</b-link></li>
              </ul>
            </div>
            <div class="more-info">
              <h5 class="more-info__ttl">Thông tin tổ chức đấu giá</h5>
              <ul class="list-info-cus">
                <li><img src="@/assets/icon/Company.png" alt="">Đơn vị tổ chức: <span class="text-red">{{ dataAuctions.org }}</span></li>
                <li><img src="@/assets/icon/Customer.png" alt="">Đấu giá viên: <span class="text-red">{{ dataAuctions.DGV }}</span></li>
                <li><img src="@/assets/icon/Location.png" alt="">Địa điểm: <span class="text-red">{{ dataAuctions.auction_address }}</span></li>
              </ul>
            </div>
          </b-col>
        </b-row>
        <!-- <SameProduct /> -->
      </b-container>
    </div>
  </template>
  <script>
//   import SameProduct from "../components/sameproduct.vue";
  
  export default {
    components: {
    //   SameProduct,
    },
    data() {
      return {
        items: [
          {
            text: 'Trang chủ',
            to: '/',
          },
          {
            text: 'Thông tin chi tiết đấu giá',
            active: true
          },
        ],
        asset_id: '',
        dataAuctions: [],
      }
    },
    created() {
        this.asset_id = this.decode(this.$route.params.asset_id);
        this.getData()
    },
    methods: {
      async getData() {
          await this.$axios.get('/asset/getAssetDetail?asset_id=' + this.asset_id, {
            headers: {
                    'x-access-token': ''
                }
          }).then(res => {
            this.dataAuctions = res.data
          }).catch(err => {
              if(err.response.status == 409) {
                  localStorage.removeItem('auInfo')
                  this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                  this.$router.push('/loginAuctioneer')
              } else if(err.response.status == 401) {
                  localStorage.removeItem('auInfo')
                  this.$toast.error('Phiên đăng nhập đã hết hạn!')
                  this.$router.push('/loginAuctioneer')
              }
          })
        }
      }
    }
  </script>
  <style lang="css" scoped>
  .auction_detail {
    color: #515151;
    background-image: url('@/assets/images/logo_moreinfo.png');
    background-repeat: no-repeat;
    background-position: top;
    min-height: 90vh;
  }
  
  .auction_detail .title {
    font-size: 24px;
    line-height: 30px;
    font-family: Libre Bodoni;
    color: #515151;
    padding-bottom: 16px;
    border-bottom: 1px solid #E1E1E1;
  }
  
  .auction_detail .noti {
    text-align: center;
    font-weight: 500;
    padding: 15px 0;
  }
  
  .auction_detail .info {
    font-size: 18px;
    font-family: Roboto;
    line-height: 32.4px;
  }
  
  .auction_detail .more-info__ttl {
    font-weight: 500;
    font-size: 22px;
    line-height: 39.6px;
  }
  
  .auction_detail li {
    padding: 5px 0;
  }
  
  .auction_detail .list-info li {
    position: relative;
    margin-left: 33px;
    cursor: pointer;
  }
  
  .auction_detail .list-info li::before {
    content: " ";
    background: url('@/assets/icon/Note.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    position: absolute;
    left: -33px;
    top: 3px;
  }
  
  .auction_detail .list-info-cus img {
    padding-right: 14px;
  }
  
  .auction_detail .text-red {
    color: #EE3439;
    font-size: 16px;
    font-weight: 500;
    line-height: 28.8px;
    font-family: Roboto;
  }
  
  @media (max-width: 768px) {
  
    .auction_detail .title {
      font-size: 20px;
    }
  
    .auction_detail .info {
      font-size: 16px;
    }
  
    .auction_detail .noti {
      font-size: 18px;
    }
  
    .auction_detail .more-info,
    .auction_detail .text-red {
      font-size: 14px;
    }
  
    .auction_detail .more-info__ttl {
      font-size: 16px;
    }
  }
  </style>