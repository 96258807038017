<template>
    <div>
        <b-container class="auction">
            <b-breadcrumb :items="items"></b-breadcrumb>
            <b-row>
                <h5 class="title">Cuộc đấu giá: <span>Chi tiết cuộc đấu giá</span></h5>
            </b-row>

            <b-row>
                <b-col cols="md-3" class="col-100">

                    <b-form class="form-search" @submit.prevent="filterAsset()">
                        <b-form-group id="input-group-1" label-for="input-1">
                            <b-form-input id="input-1" placeholder="Tìm kiếm tài sản đấu giá" required class="input-search"
                                v-model="paramsAsset.asset_name" ></b-form-input>
                            <img class="search_ico" src="@/assets/icon/Search.png" alt="" @click="filterAsset()">
                        </b-form-group>
                    </b-form>
                    <div class="cate-img">
                        <img class="img-cate" src="@/assets/images/auction_img.png" alt="">
                        <p class="cate-name">{{ cate_name }}</p>
                    </div>
                    <div class="show-news">
                        <h5>Tin tức và thông báo mới</h5>
                        <div class="mb-2" v-for="(item, index) in listNews.result" :key="index">
                            <cardNews :item="item"></cardNews>
                        </div>
                    </div>
                </b-col>

                <b-col cols="md-9" class="w-100-768">
                    <div>
                        <b-col cols="md-12">
                            <b-link class="item-auction">
                                <div class="">
                                    <img class="item-auction_img" :src="this.$storage + auctionDetail.thumbnail" alt="" @error="handleError">
                                    <img v-if="auctionDetail.auction_status_id == 1" class="auction-item_ico auction-item_ico--child" src="@/assets/icon/Auction2.png" alt="">
                                    <img class="auction-item_ico auction-item_ico--child"
                                        v-if="auctionDetail.auction_status_id == 2 || auctionDetail.auction_status_id == 3" src="@/assets/icon/golden sold out.png" alt="">
                                </div>
                                <div style="margin-left: 10px;" class="col-8">
                                    <p class="item-auction_txt">{{ auctionDetail.auction_name }}</p>

                                    <div class="item-auction-listinfo">
                                        <ul>
                                            <li>Mã cuộc đấu giá: {{ auctionDetail.auction_code }}</li>
                                            <li>Đấu giá viên: {{ auctionDetail.full_name }}</li>
                                            <li>Thời gian bắt đầu: {{ formateTime(auctionDetail.start_time) }}</li>
                                            <li>Thời gian kết thúc: {{ formateTime(auctionDetail.end_time) }}</li>
                                            <li>Số tài sản đấu giá: {{ auctionDetail.count_asset }}</li>
                                        </ul>
                                        <div class="text-center">
                                            <div class="status" v-if="auctionDetail.auction_type == '0'">
                                                <img src="@/assets/icon/icon_auction.png" alt="">
                                                <p class="status-auction status-auction--pending">Chưa đấu giá</p>
                                            </div>
                                            <div class="status" v-if="auctionDetail.auction_type == '1'">
                                                <img src="@/assets/icon/icon_auction.png" alt="">
                                                <p class="status-auction status-auction--pending">Đang đấu giá</p>
                                            </div>
                                            <div class="status" v-if="auctionDetail.auction_type == '2'">
                                                <img src="@/assets/icon/Ok.png" alt="">
                                                <p class="status-auction">Đấu giá xong - chờ xác nhận</p>
                                            </div>
                                            <div class="status" v-if="auctionDetail.auction_type == '3'">
                                                <img src="@/assets/icon/close.png" alt="">
                                                <p class="status-auction status-auction--pending">Đấu giá không thành</p>
                                            </div>
                                            <div class="status" v-if="auctionDetail.auction_type == '4'">
                                                <img src="@/assets/icon/Ok.png" alt="">
                                                <p class="status-auction">Đấu giá thành</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-link>
                            <div class="d-flex show-detail">
                                <p>Danh sách tài sản trong cuộc đấu giá</p>
                            </div>
                            <b-row class="row-width">
                                <b-col cols="xl-4 col-lg-6 col-md-12" v-for="(data, index) in listAsset.result"
                                    :key="index" @click="routeAsset(data.asset_id)">
                                    <cardAsset :item="data"  />
                                </b-col>
                            </b-row>
                        </b-col>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import cardNews from '../components/card-news.vue';
import cardAsset from '../components/card-asset.vue';
export default {
    components: {
        cardNews,
        cardAsset
    },
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    to: '/',
                },
                {
                    text: 'Cuộc đấu giá',
                    active: true
                },
            ],

            listNews: {},
            listAsset: {},
            auctionDetail: {},
            paramsAsset: {
                asset_name: '',
                asset_code: '',
                category_id: '',
                owner: '',
                to_price: '',
                from_price: '',
                from_step_price: '',
                to_step_price: '',
                winner: '',
                status: '',
                description: '',
                page: 1,
                per_page: 10,
                order: 'latest',
                auction_id: this.$route.params.auction_id,
            },
            paramsNews: {
                page: 1,
                per_page: 2,
            }
        }
    },
    mounted() {
        this.getListNews(this.paramsNews)
        this.getAuctionDetailForMember({
            auction_id: this.paramsAsset.auction_id,
        })
        this.getListAssetForMember(this.paramsAsset)
    },
    computed: {
        cate_name() {
            if (this.auctionDetail.auction_type == '0') {
                return "Cuộc đấu giá sắp diễn ra"
            } else if (this.auctionDetail.auction_type == '1') {
                return "Cuộc đấu giá đang diễn ra"
            } else if (this.auctionDetail.auction_type == '2' || this.auctionDetail.auction_type == '3') {
                return "Cuộc đấu giá đã kết thúc"
            } else {
                return "Cuộc đấu giá"
            }
        }
    },
    methods: {
        routeAsset(asset_id) {
            if (this.auctionDetail.auction_status_id == 2 || this.auctionDetail.auction_status_id == 3) {
                this.$router.push({
                    path: "/result-auction/" + this.encode(asset_id),
                })
            } else {
                this.$router.push({
                    path: "/product-detail/" + this.encode(asset_id),
                })
            }
        }
    }
}
</script>

<style lang="css" scoped>
.auction-list {
    padding: 0;
}

.auction-list li {
    padding-left: 55px;
}

.auction-list li:first-child {
    padding-left: 30px;
}

.auction-list li a {
    color: #515151;
    font-size: 18px;
    line-height: 32.4px;
    font-weight: 500;
    text-decoration: none;
    font-family: Roboto;
}

.auction-list li a.active {
    font-weight: 700;
    color: #EE3439;
    position: relative;
}

.auction-list li:first-child a {
    font-family: Libre Bodoni;
    font-weight: 700;
}

.auction-list li a.active::before {
    content: "";
    position: absolute;
    background: url('@/assets/icon/Polygon\ 2.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    left: -26px;
    top: 2px;
}

.auction-item {
    display: block;
    width: 100%;
    background: #F8F8F8;
    border: 1px solid #D8D8D8;
    border-radius: 14px;
    font-size: 14.05px;
    text-decoration: none;
    color: #515151;
    font-family: Roboto;
    margin-bottom: 10px;
    position: relative;
}

.auction-item_img {
    width: 307px;
    height: 205px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    object-fit: cover;
}

.auction-item_ico {
    position: absolute;
    top: 2%;
    left: 3%
}

.auction-item_txt {
    line-height: 25.29px;
    padding: 5px 7px 3px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 58px;
}

.auction-item_time {
    color: #929292;
    text-align: center;
    position: relative;
    padding: 0 8px 14px;
    margin: 0;
}

.auction-item_time--highlight {
    color: #EE3439;
    display: block;
    font-size: 16px;
    font-weight: 600;
}

.auction-item_time::before {
    content: "";
    background-image: url('@/assets/icon/Auction1.png');
    position: absolute;
    width: 44px;
    height: 44px;
    left: 5px;
    background-repeat: no-repeat;
}

@media (max-width: 1200px) {
    .auction-list li {
        padding-left: 20px;
    }
}

@media (max-width: 1024px) {

    .cate-name {
        font-size: 24px;
    }

    .auction-list li a {
        font-size: 16px;
    }

    .auction-item_time {
        padding-left: 44px;
    }
}

@media (max-width: 768px) {
    .w-100-768 {
        width: 100%;
    }

    .cate-name {
        font-size: 30px;
        width: 40%;
    }

    .auction-list {
        margin-top: 25px;
    }

    .auction-list li a {
        font-size: 18px;
    }

    .auction-list li {
        padding-left: 50px;
    }

    .auction-item_time {
        padding-left: 44px;
    }
}

@media (max-width: 425px) {
    .cate-name {
        font-size: 18px;
        width: 50%;
    }

    .auction-item_time {
        padding-left: 0;
    }
}

.item-auction {
    display: flex;
    color: #515151;
    background: #F8F8F8;
    padding: 11px 11px 15px;
    text-decoration: none;
    border-radius: 35px;
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.item-auction_txt {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 9px;
    line-height: 23.44px;
}

.item-auction ul {
    margin-bottom: 0;
    list-style-type: disc;
    padding-left: 2rem;
}

.item-auction ul li {
    padding-bottom: 5px;
}

.item-auction ul li:last-child {
    padding-bottom: 0;
}

.show-detail {
    justify-content: space-between;
}

.show-detail p {
    color: #515151;
    font-size: 18px;
    line-height: 32.4px;
    margin: 10px 0 15px;
    font-weight: 500
}


/* ------- */
.item-in-auction {
    font-size: 14.05px;
    font-weight: 400;
    color: #515151;
    display: block;
    text-decoration: none;
    background-color: #F8F8F8;
    border-radius: 20px;
    padding-bottom: 5px;
}

.item-in-auction img {
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.item-in-auction p {
    margin: 0;
    padding: 5px 7px 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.item-in-auction ul {
    border: 1px solid #515151;
    border-radius: 18.73px;
    padding: 5px 0;
    margin: 10px auto;
    color: #929292;
    position: relative;
    max-width: 280px;
}

.item-in-auction ul span {
    color: #515151;
    font-weight: 500;
}

.item-in-auction ul::before {
    content: "";
    position: absolute;
    background-image: url("@/assets/icon/Auction1.png");
    background-repeat: no-repeat;
    width: 44px;
    height: 44px;
    top: 16px;
}

.item-in-auction ul li {
    padding-left: 50px;
    padding-bottom: 3px;
}

.row-width {
    margin-bottom: 15px;
}

.item-auction-listinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.status-auction {
    margin-bottom: 0;
    color: #00B569;
    font-weight: 500;
    max-width: 126px;
    font-size: 15px;
    margin-top: 5px;
}

.status-auction--pending {
    color: #EE3439;
}

.item-auction_img {
    width: 297px;
    height: 223px;
    border-radius: 15px;
    object-fit: cover;
}

.status img {
    max-width: 50px;
    max-height: 50px;
}

.image-status {
    position: absolute;
    z-index: 90;
    top: 3%;
    left: 3%;
}

.image-status--list {
    left: 7%;
}

@media (max-width: 1024px) {
    .item-auction-listinfo {
        font-size: 14px;
    }

    .item-auction_txt {
        font-size: 17px;
    }
}

@media (max-width: 768px) {

    .item-auction {
        display: block;
    }

    .row-width .col-md-4 {
        width: 50%;
    }

    .item-auction_img,
    .item-in-auction img {
        width: 100%;
    }

    .item-auction-listinfo {
        display: block;
    }

    .item-auction-listinfo ul {
        margin-bottom: 15px;
    }

    .status-auction {
        max-width: 100%;
    }
}

@media (max-width: 425px) {
    .item-auction {
        display: block;
        font-size: 14px;
    }

    .item-auction_txt,
    .show-detail p {
        font-size: 14px;
    }

    .row-width .col-md-4 {
        width: 100%;
    }

}</style>