<template>
    <div class="au-delivery mt-5 pl-2">
        <div class="text-right">
            <button class="btn-add" @click="$router.push('auction/add-new')">+ Tạo cuộc đấu giá mới</button>
        </div>
        <h5 class="au_title">Lưu ý: Trước giờ đấu giá 30 phút, phải tạo phòng đấu giá để điểm danh khách hàng trước khi
            thời gian đấu giá bắt đầu.</h5>
        <b-row class="item mx-3" style="cursor: pointer;" v-for="(data, index) in dataAuction" :key="index"
            @click="$router.push('/auctioneer/auction/list-asset/' + encode(data.auction_id))">
            <b-col cols="auto" class="p-1 wp-img">
                <img class="img_pro" :src="$storage + data.thumbnail" alt="" @error="handleError">
            </b-col>
            <b-col cols="lg-7" class="p-1">
                <p class="item_name">{{ data.auction_name }}</p>
                <div class="d-flex justify-content-between block-mobile">
                    <div class="info_pro">
                        <p>Mã cuộc đấu giá: <span>{{ data.auction_code }}</span></p>
                        <p>Số tài sản đấu: <span>{{ data.count_asset }}</span></p>
                        <p>Đấu giá viên: <span>{{ data.full_name }}</span></p>
                    </div>
                    <div class="info_pro">
                        <p>Thời gian bắt đầu: <span>{{ formateTime(data.start_time) }}</span></p>
                        <p>Thời gian kết thúc: <span>{{ formateTime(data.end_time) }}</span></p>
                        <p><span>{{ data.value }}</span></p>
                    </div>
                </div>
            </b-col>
            <b-col cols="lg-2" class="text-center au_time p-1">
                <img src="@/assets/front-end/icon/Group1370.png" alt="">
                <p>Thời gian đến đấu giá còn:</p>
                <countdown :duration="data.start_time" :key="data.auction_id"/>
                <button @click="$router.push('wait-room/' + encode(data.asset_id))" v-if="data.create_room_status == 0"
                >Tạo phòng đấu giá</button>
                <button @click="$router.push('/auctioneer/auction-room/' + encode(data.auction_id))" v-else
                >Vào phòng đấu giá</button>
                <!-- <button @click="$router.push('wait-room/' + encode(data.asset_id))" v-if="data.create_room_status == 0"
                    :disabled="new Date(data.start_time).getTime() - new Date().getTime() - 25200000 >= 1800000 || new Date(data.start_time).getTime() - new Date().getTime() - 25200000 <= 0"
                >Tạo phòng đấu giá</button> -->
                <!-- <button @click="$router.push('/auctioneer/auction-room/' + encode(data.auction_id))" v-else
                    :disabled="new Date(data.start_time).getTime() - new Date().getTime() - 25200000 >= 1800000 || new Date(data.start_time).getTime() - new Date().getTime() - 25200000 <= 0"
                >Vào phòng đấu giá</button> -->
            </b-col>
        </b-row>
        <Paginate :page=page @set-page="setPage" />
    </div>
</template>
<script>
import Paginate from '../../../../web-view/components/paginate.vue'
import countdown from '../../../../web-view/account/countdown.vue'
export default {
    components: {
        Paginate,
        countdown
    },
    data() {
        return {
            dataAuction: [],
            page: {
                totalData: 0,
                totalPage: 0,
                currentPage: this.$route.query.page || 1,
                perPage: 4,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
            timeLeft: 0,
            timeEnd: ''
        }
    },
    computed: {
    },
    mounted() {
        this.getDataAuction()
    },
    methods: {
        countdown(end_time) {
            setInterval((res) => {
                let endTime = new Date(end_time).getTime();
                let now = new Date().getTime();
                let countdown = endTime - now
                var timeCount
                if (countdown - 25200000 !== 0 && countdown - 25200000 > 0) {
                    timeCount = parseInt(countdown);
                    this.startCountdown(timeCount);
                }
                return timeCount ? this.formatTime(timeCount) : '00:00:00';
            })
        },
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss DD/MM/YYYY")
        },
        async getDataAuction() {
            const storedData = localStorage.getItem('auInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }
            const data = {
                page: this.page.currentPage,
                perPage: this.page.perPage,
            };
            await this.$axios.get('/auction/getListAuctionUpcoming?page=' + data.page + '&per_page=' + data.perPage, {
                headers: {
                    'x-access-token': info.accessToken
                }
            }).then(res => {
                this.dataAuction = res.data.re
                this.page.totalData = res.data.pagination.total_element
                this.page.totalPage = res.data.pagination.total_page

                this.dataAuction.forEach(ele => {
                    this.timeEnd = ele.start_time
                });

                //SET START TIME
                let endTime = new Date(this.timeEnd).getTime();
                let now = new Date().getTime();
                let countdown = endTime - now
                if (countdown !== 0 && countdown > 0) {
                    this.timeLeft = parseInt(countdown);
                    this.startCountdown(this.timeLeft);
                }
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAuctioneer')
                }else if(err.response.status == 401) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAuctioneer')
                }
            })
        },
        creatRoom(auction_key, auction_id) {
            const key = '';
            const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;
            this.$socket(auction_key, key, token).emit('createRoom')
            this.$router.push('/auctioneer/auction-room/' + auction_id)
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getDataAuction();
        },
    }
}
</script>
<style lang="css" scoped>
.btn-add {
    background: none;
    color: #EE3439;
    font-size: 18px;
    font-weight: 500;
    border: 1px solid #EE3439;
    border-radius: 20px;
    padding: 6px 20px;
    margin-bottom: 20px;
}

.au_title {
    color: #515151;
    font-family: Libre Bodoni;
    font-weight: 500;
    font-size: 19px;
    position: relative;
    padding-left: 25px;
    margin-bottom: 8px;
}

.au_title::before {
    content: '';
    position: absolute;
    background-image: url('@/assets/icon/Important.png');
    background-repeat: no-repeat;
    width: 24px;
    height: 20px;
    left: 0;
}

.item {
    box-shadow: 2px 2px 4px 0px #00000021;
    border: 1px solid #EAEAEA;
    border-radius: 30px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: #FFFFFF;
}

.item .img_pro {
    border-radius: 30px;
    height: 190px;
    width: 190px;
    object-fit: cover;
}

.item_name {
    color: #515151;
    font-weight: 600;
    margin: 5px 0 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.info_pro p {
    font-size: 14px;
    color: #929292;
    margin-bottom: 12px;
}

.info_pro p span {
    color: #515151;
    font-weight: 500;
}

.au_time p {
    font-size: 12px;
    margin: 0;
    color: #EE3439;
    font-weight: 600;
}

.au_time button {
    border: none;
    background: #C22924;
    border-radius: 12px;
    color: #EEEEEE;
    font-weight: 500;
    font-size: 15px;
    padding: 5px 7px;
    margin-top: 5px;
    width: max-content;
}


@media (max-width: 1400px) {
    .au_time button {
        font-size: 13px;
        padding: 5px 5px;
    }
    .info_pro p{
        font-size: 13px;
    }
}


@media (max-width: 1200px) {
    .au_time button {
        font-size: 14px;
    }
}


@media (max-width: 992px) {
    .block-mobile {
        justify-content: space-around !important;
    }
    .item_name {
        text-align: center;
    }

    .au_time {
        margin-bottom: 10px;
    }

    .wp-img {
        text-align: center;
    }
}

@media (max-width: 991px) {
    .item {
        justify-content: center;
    }
}

@media (max-width: 768px) {

    .block-mobile,
    .item_name {
        display: block !important;
        margin-left: 20px;
    }

    .item {
        padding: 10px 0;
    }
}</style>