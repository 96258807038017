
<template>
    <div class="auth register" :class="{ 'overlay': isLoading }">
        <div class="bg-transparent">
            <div class="banner-main first">
                <div class="container py-5" style="height: inherit;">
                    <b-row>
                        <div class="col-lg-4 logo-login">
                            <img src="@/assets/front-end/img/Group 467.png" alt="">
                        </div>

                        <div class="col-lg-8 justify-content-center ml-0">
                            <label class="login-tab my-3 text-center"><a href="">Đăng ký tài khoản</a></Label>
                                <!-- v-if="verifiOTP && verifiEmail" -->
                            <div class="col-12 p-0 mb-5" >
                                <div class="tab-content" id="nav-tabContent-register">
                                    <div class="tab-pane fade show active" id="personal" role="tabpanel"
                                        aria-labelledby="personal-tab">
                                        <form action="" id="register-member" method="post" @submit.prevent="__register(formData)">
                                            <div class="row pl-md-4">
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center">
                                                            <label class="">Loại tài khoản </label>
                                                        </div>
                                                        <div
                                                            class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                            <label class="reg-fn mb-2 d-sm-none">Loại tài khoản </label>
                                                            <b-form-radio-group class="gender">
                                                                <b-form-radio value="1" class="btn-radio"
                                                                    v-model="formData.type"
                                                                    :class="{ 'active': formData.type == 1 }">
                                                                    <div class="d-flex align-items-center">
                                                                        <img src="@/assets/front-end/img/Ellipse.png"
                                                                            style="width: 15px;height: 15px;" alt="">
                                                                        <label class="">Cá nhân</label>
                                                                    </div>
                                                                </b-form-radio>
                                                                <b-form-radio value="2" class="btn-radio"
                                                                    v-model="formData.type"
                                                                    :class="{ 'active': formData.type == 2 }">
                                                                    <div class="d-flex align-items-center">
                                                                        <img src="@/assets/front-end/img/Ellipse.png"
                                                                            style="width: 15px;height: 15px;" alt="">
                                                                        <label class="">Tổ chức</label>
                                                                    </div>
                                                                </b-form-radio>
                                                            </b-form-radio-group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center">
                                                            <label class="" v-if="formData.type == 1">Họ và tên <span style="color: #ee3439;">*</span> </label>
                                                            <label class="" v-else>Người đại diện theo pháp luật <span style="color: #ee3439;">*</span> </label>
                                                        </div>
                                                        <div
                                                            class="form-check flex-md-row align-items-center col-md-7 col-xs-12">
                                                            <label class="reg-fn mb-2 d-sm-none"
                                                                v-if="formData.type == 1">Họ và tên <span style="color: #ee3439;">*</span></label>
                                                            <label class="reg-fn mb-2 d-sm-none" v-else>Người đại diện theo
                                                                pháp luật <span style="color: #ee3439;">*</span></label>
                                                            <input class="form-control" name="member_name" required @input="inputTxt"
                                                                placeholder="Họ - Tên đệm - Tên" v-if="formData.type == 1"
                                                                v-model="formData.member_name">
                                                            <input class="form-control" name="member_name" required
                                                                placeholder="Họ - Tên đệm - Tên" v-else
                                                                v-model="formData.rep_name" @input="inputTxt">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center">
                                                            <label class="">Giới tính</label>
                                                        </div>
                                                        <div
                                                            class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                            <label class="reg-fn mb-2 d-sm-none">Giới tính</label>
                                                            <b-form-radio-group class="gender">
                                                                <b-form-radio value="0" class="btn-radio"
                                                                    v-model="formData.gender"
                                                                    :class="{ 'active': formData.gender == 0 }">
                                                                    <div class="d-flex align-items-center">
                                                                        <img src="@/assets/front-end/img/Ellipse.png"
                                                                            style="width: 15px;height: 15px;" alt="">
                                                                        <label class="">Nam</label>
                                                                    </div>
                                                                </b-form-radio>
                                                                <b-form-radio value="1" class="btn-radio"
                                                                    v-model="formData.gender"
                                                                    :class="{ 'active': formData.gender == 1 }">
                                                                    <div class="d-flex align-items-center">
                                                                        <img src="@/assets/front-end/img/Ellipse.png"
                                                                            style="width: 15px;height: 15px;" alt="">
                                                                        <label class="">Nữ</label>
                                                                    </div>
                                                                </b-form-radio>
                                                            </b-form-radio-group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0" v-if="formData.type == 2">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center">
                                                            <label class="">Chức vụ</label>
                                                        </div>
                                                        <div
                                                            class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                            <label class="reg-fn mb-2 d-sm-none">Chức vụ</label>
                                                            <input class="form-control" value=""
                                                                placeholder="Chức vụ" v-model="formData.position" name="position" @input="inputTxt">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center text-justify">
                                                            <label class="">Số điện thoại ( di động ) <span style="color: #ee3439;">*</span> </label>
                                                        </div>
                                                        <div
                                                            class="form-check flex-wrap flex-md-row align-items-center col-md-7 col-xs-12">
                                                            <label class="reg-fn mb-2 d-sm-none">
                                                                Số điện thoại ( di động ) <span style="color: #ee3439;">*</span>
                                                            </label>
                                                            <input class="form-control format-number" required name="phone"
                                                                maxlength="10" minlength="10" v-model="formData.phone" @input="__checkNumber"
                                                                type="text" placeholder="Nhập số điện thoại di động">
                                                                <!-- <span style="color: #FFF; font-weight: 500;">{{ formOTP.phone }}</span> -->
                                                                <!-- @change="checkDuplicatePhone"> -->
                                                            <label class="col-12 mt-2 pl-2 text_red" v-if="duplicatePhone">
                                                                {{ duplicatePhone }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center">
                                                            <label class="">Địa chỉ email <span style="color: #ee3439;">*</span> </label>
                                                        </div>
                                                        <div
                                                            class="form-check d-md-flex flex-wrap align-items-center col-md-7 col-xs-12">
                                                            <label class="reg-fn mb-2 d-sm-none">Địa chỉ email <span style="color: #ee3439;">*</span></label>
                                                            <input type="email" class="form-control col-12" required @change="checkDuplicateEmail"
                                                                placeholder="Nhập email liên hệ" v-model="formData.email" name="email" @input="inputTxt">
                                                            <label class="col-12 mt-2 pl-2 text_red" v-if="duplicateEmail">
                                                                {{ duplicateEmail }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center">
                                                            <label class="">Mật khẩu <span style="color: #ee3439;">*</span> </label>
                                                        </div>
                                                        <div class="form-check d-md-flex flex-wrap align-items-center col-md-7 col-xs-12"
                                                            style="position: relative;">
                                                            <label class="reg-fn mb-2 d-sm-none">Mật khẩu <span style="color: #ee3439;">*</span></label>
                                                            <div class="d-md-flex align-items-center w-100">
                                                                <input class="form-control col-12" type="password"
                                                                    placeholder="Tối thiểu 8 ký tự" required minlength="8"
                                                                    @input="__validatePassword" v-model="sendPassword"
                                                                    ref="inputPass" autocomplete="true">
                                                                <a href="#" class="toggel-password" @click="__show_password('pass')">
                                                                    <b-icon icon="eye" font-scale="0.5"
                                                                        v-if="this.iconStt"></b-icon>
                                                                    <b-icon icon="eye-slash" font-scale="0.5" v-else></b-icon>
                                                                </a>
                                                            </div>
                                                            <label v-if="sendPassword && !__validatePassword()"
                                                                class="col-12 mt-2 pl-2 text_red">Mật khẩu tối thiểu 8 ký tự bao gồm chữ Hoa, chữ thường, số và ký tự đặc biệt</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center"
                                                            style="position: relative;">
                                                            <label class="">Nhập lại mật khẩu <span style="color: #ee3439;">*</span> </label>
                                                        </div>
                                                        <div
                                                            class="form-check d-md-flex flex-wrap align-items-center col-md-7 col-xs-12">
                                                            <label class="reg-fn mb-2 d-sm-none">Nhập lại mật khẩu <span style="color: #ee3439;">*</span></label>
                                                                <div class="d-md-flex align-items-center w-100">
                                                                <input class="form-control" type="password"
                                                                    placeholder="Tối thiểu 8 ký tự" required minlength="8"
                                                                    v-model="rePassword" ref="inputRepass" autocomplete="true">
                                                                <a href="#" class="toggel-password" @click="__show_password('repass')">
                                                                    <b-icon icon="eye" font-scale="0.5"
                                                                        v-if="this.iconSttRepass"></b-icon>
                                                                    <b-icon icon="eye-slash" font-scale="0.5" v-else></b-icon>
                                                                </a>
                                                            </div>
                                                            <label v-if="rePassword != sendPassword && rePassword"
                                                                class="col-12 mt-2 pl-2 text_red">Mật khẩu không khớp
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center">
                                                            <label class="">Ngày sinh <span style="color: #ee3439;">*</span></label>
                                                        </div>
                                                        <div
                                                            class="form-check d-md-flex flex-wrap align-items-center col-md-7 col-xs-12">
                                                            <label class="reg-fn mb-2 d-sm-none">Ngày sinh <span style="color: #ee3439;">*</span></label>
                                                            <div class="col-12 p-0 p-0 d-flex" @change="checkBirthDay()">
                                                                <select class="form-select col-4" name="day" v-model="formData.birthday_d" required>
                                                                    <option value=''>Ngày</option>
                                                                    <option v-for="d in valueDay" :value="formatpreNumber(d)">
                                                                        {{ formatpreNumber(d) }}
                                                                    </option>
                                                                </select>
                                                                <select class="form-select col-4" name="month" required
                                                                    @change="__getDayOfMonth" v-model="formData.birthday_m">
                                                                    <option value=''>Tháng </option>
                                                                    <option v-for="m in 12" :value="formatpreNumber(m)">{{ formatpreNumber(m) }}</option>
                                                                </select>
                                                                <select class="form-select col-4" name="year" v-model="formData.birthday_y" required>
                                                                    <option value=''>Năm </option>
                                                                    <option v-for="year in years.slice().reverse()"
                                                                        :value="year">{{ year }}</option>
                                                                </select>
                                                            </div>
                                                            <label class="col-12 mt-2 pl-2 text_red" v-if="duplicateBirthDay">
                                                                {{ duplicateBirthDay }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center">
                                                            <label class="">Địa chỉ</label>
                                                        </div>
                                                        <div
                                                            class="form-check d-md-flex align-items-center col-md-7 col-xs-12 row ml-0">
                                                            <label class="reg-fn mb-2 d-sm-none">Địa chỉ</label>
                                                            <div class="col-12 p-0 p-0 d-flex form-group">
                                                                <select class="form-select" name="city"
                                                                    @change="__changeCity" v-model="formData.province_id">
                                                                    <option value="0">Tỉnh/Thành phố</option>
                                                                    <option v-for="item in dataCity"
                                                                        :value="item.code">{{ item.name }}
                                                                    </option>
                                                                </select>

                                                                <select class="form-select" name="district"
                                                                    @change="__changeDistrict" v-model="formData.district_id">
                                                                    <option value="0">Quận/Huyện</option>
                                                                    <option v-for="district in dataDistricts"
                                                                        :value="district.code">
                                                                        {{ district.name }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-6 p-0 form-group">
                                                                <select class="form-select" name="ward"
                                                                    v-model="formData.ward_id">
                                                                    <option value="0" selected>Xã /Phường</option>
                                                                    <option v-for="ward in dataWard" :value="ward.code">
                                                                        {{ ward.name }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-12 p-0 p-0">
                                                                <input class="form-control" value=""
                                                                    placeholder="Địa chỉ chi tiết" name="member_address" @input="inputTxt"
                                                                    v-model="formData.member_address" v-if="formData.type == 1">
                                                                <input class="form-control" value=""
                                                                    placeholder="Địa chỉ chi tiết" name="rep_address" @input="inputTxt"
                                                                    v-model="formData.rep_address" v-else>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center">
                                                            <label class="">Số CMT/ Thẻ căn cước /Hộ chiếu <span style="color: #ee3439;">*</span> </label>
                                                        </div>
                                                        <div
                                                            class="form-check d-md-flex flex-wrap align-items-center col-md-7 col-xs-12">
                                                            <label class="reg-fn mb-2 d-sm-none">Số CMT/ Thẻ căn cước /Hộ
                                                                chiếu <span style="color: #ee3439;">*</span></label>
                                                            <input class="form-control format-number"
                                                                v-model="formData.id_number" @input="__checkNumber"
                                                                type="text" required placeholder="Nhập số"
                                                                @change="checkDuplicateId">
                                                            <label class="col-12 mt-2 pl-2 text_red" v-if="duplicateId">
                                                                {{ duplicateId }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex">
                                                        <div class="col-5 d-none-mobie align-self-center">
                                                            <label class="">Ngày cấp, nơi cấp CMT / Thẻ căn cước / Hộ chiếu <span style="color: #ee3439;">*</span> 
                                                            </label>
                                                        </div>
                                                        <div
                                                            class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                            <label class="reg-fn mb-2 d-sm-none">
                                                                Ngày cấp, nơi cấp CMT / Thẻ căn cước / Hộ chiếu <span style="color: #ee3439;">*</span></label>
                                                            <div class="col-12 p-0 p-0 d-flex">
                                                                <div class="col-6 pl-0 pr-2">
                                                                    <b-form-datepicker class="mb-2 text-center" :max="max" v-model="formData.id_date"
                                                                        locale="vi" start-weekday=1 placeholder="dd/mm/yyyy"
                                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                                                    </b-form-datepicker>
                                                                </div>
                                                                <div class="col-6 pr-0 pl-2">
                                                                    <input class="form-control placeholder-center"
                                                                        required v-model="formData.id_place" name="id_place"
                                                                        placeholder="Nơi cấp CMT, CC, HC" @input="inputTxt">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="form-group d-flex align-items-center">
                                                        <div class="col-5 d-none-mobie align-self-center">
                                                            <label class="">Ảnh 2 mặt CMT / Thẻ căn cước / Hộ Chiếu  <span style="color: #ee3439;">*</span> </label>
                                                        </div>
                                                        <div
                                                            class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                            <label class="reg-fn mb-2 d-sm-none">
                                                                Ảnh 2 mặt CMT / Thẻ căn cước / Hộ Chiếu <span style="color: #ee3439;">*</span></label>
                                                            <div class="col-12 p-0 p-0 d-flex">
                                                                <div class="col-5 text-center">
                                                                    <label class="d-flex justify-content-center">
                                                                        Mặt trước</label>
                                                                    <input type="file" name="id_front_face" accept="image/png, image/gif, image/jpeg, image/jpg"
                                                                        ref="inputFileFront" id="id_front_face"
                                                                        class="image_input_review uploadFront" hidden
                                                                        @change="__uploadFile">
                                                                    <img class="review-img" onclick="$('#id_front_face').click()"
                                                                        src="@/assets/front-end/img/front-identifi.png"
                                                                        alt="" ref="id_front_face">
                                                                </div>
                                                                <div class="col-2"></div>
                                                                <div class="col-5 text-center">
                                                                    <label class="d-flex justify-content-center">
                                                                        Mặt sau</label>
                                                                    <input type="file" name="id_back_side" id="back_id_1"
                                                                        class="image_input_review uploadBack" hidden
                                                                        ref="inputFile" @change="__uploadFile"
                                                                        accept="image/png, image/gif, image/jpeg, image/jpg">
                                                                    <img class="review-img" id="review_back_id_1"
                                                                        src="@/assets/front-end/img/back-identifi.png"
                                                                       onclick="$('#back_id_1').click()" alt="" ref="id_back_side">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- -----------REGISTER ORGANIZATION---------- -->
                                                <div v-if="formData.type == 2" class="pl-0">
                                                    <div class="col-12 p-0">
                                                        <div class="form-group d-flex">
                                                            <div class="col-5 d-none-mobie align-self-center">
                                                                <label class="">Tên tổ chức / công ty / doanh nghiệp <span style="color: #ee3439;">*</span></label>
                                                            </div>
                                                            <div
                                                                class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                                <label class="reg-fn mb-2 d-sm-none">Nhập tên <span style="color: #ee3439;">*</span></label>
                                                                <input class="form-control" name="member_name" @input="inputTxt"
                                                                    required placeholder="Nhập tên" v-model="formData.member_name">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="form-group d-flex">
                                                            <div class="col-5 d-none-mobie align-self-center">
                                                                <label class="">Mã số doanh nghiệp / mã số thuế <span style="color: #ee3439;">*</span></label>
                                                            </div>
                                                            <div class="form-check flex-wrap d-md-flex align-items-center col-md-7 col-xs-12">
                                                                <label class="reg-fn mb-2 d-sm-none">
                                                                    Mã số doanh nghiệp / mã số thuế <span style="color: #ee3439;">*</span>
                                                                </label>
                                                                <input class="form-control format-number" v-model="formData.tax_code"
                                                                    required placeholder="Mã số thuế" @input="inputTxt" name="tax_code"
                                                                    @change="checkDuplicateTax">
                                                                <label class="col-12 mt-2 pl-2 text_red" v-if="duplicateTax">
                                                                    {{ duplicateTax }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="form-group d-flex">
                                                            <div class="col-5 d-none-mobie align-self-center">
                                                                <label class="">Ngày cấp mã số thuế</label>
                                                            </div>
                                                            <div
                                                                class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                                <label class="reg-fn mb-2 d-sm-none">Ngày cấp mã số
                                                                    thuế</label>
                                                                <b-form-datepicker class="mb-2" :max="max" locale="vi"
                                                                    start-weekday=1 placeholder="dd/mm/yyyy" v-model="formData.tax_date"
                                                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                                                                </b-form-datepicker>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="form-group d-flex">
                                                            <div class="col-5 d-none-mobie align-self-center">
                                                                <label class="">Nơi cấp</label>
                                                            </div>
                                                            <div
                                                                class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                                <label class="reg-fn mb-2 d-sm-none">Nơi cấp</label>
                                                                <input class="form-control" name="tax_place"
                                                                    v-model="formData.tax_place" @input="inputTxt"
                                                                    placeholder="Nơi cấp mã số thuế / mã số doanh nghiệp">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="form-group d-flex">
                                                            <div class="col-5 d-none-mobie align-self-center">
                                                                <label class="">Địa chỉ tổ chức</label>
                                                            </div>
                                                            <div
                                                                class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                                <label class="reg-fn mb-2 d-sm-none">Địa chỉ tổ chức</label>
                                                                <input class="form-control" name="member_address" v-model="formData.member_address"
                                                                    placeholder="Địa chỉ doanh nghiệp" @input="inputTxt">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="form-group d-flex">
                                                            <div class="col-5 d-none-mobie align-self-center">
                                                                <label class="">Tải lên giấy chứng nhận đăng ký kinh doanh
                                                                    ( Định dạng .pdf )</label>
                                                            </div>
                                                            <div
                                                                class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                                <label class="reg-fn mb-2 d-sm-none">Tải lên giấy chứng nhận
                                                                    đăng ký kinh doanh ( Định dạng .pdf )</label>
                                                                <input type="file" accept="application/pdf" @change="__uploadFile"
                                                                    class="form-control" name="business_registration"
                                                                    placeholder="Giấy chứng nhận đăng ký kinh doanh">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="form-group d-flex">
                                                            <div class="col-5 d-none-mobie align-self-center">
                                                                <label class="">Tải lên giấy tờ khác <br>
                                                                    ( Định dạng .pdf )</label>
                                                            </div>
                                                            <div
                                                                class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                                <label class="reg-fn mb-2 d-sm-none">Tải lên giấy tờ khác
                                                                    <br>
                                                                    ( Định dạng .pdf )</label>
                                                                <input type="file" accept="application/pdf" multiple 
                                                                    class="form-control" name="file_path" @change="__uploadFile"
                                                                    placeholder="Địa chỉ doanh nghiệp">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-0">
                                                    <div class="form-group px-1">
                                                        <label for="" style="font-size: 18px; font-weight: 500;" class="">
                                                        Thông tin ngân hàng</label>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="form-group d-flex">
                                                            <div class="col-5 d-none-mobie align-self-center">
                                                                <label class="">Tên chủ tài khoản</label>
                                                            </div>
                                                            <div
                                                                class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                                <label class="reg-fn mb-2 d-sm-none">
                                                                Tên chủ tài khoản</label>
                                                                <input class="form-control format-number" v-model="formData.bank_account_name"
                                                                    type="text" @input="inputTxt" name="bank_account_name"
                                                                    placeholder="Nhập tên chủ tài khoản">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="form-group d-flex">
                                                            <div class="col-5 d-none-mobie align-self-center">
                                                                <label class="">Ngân hàng</label>
                                                            </div>
                                                            <div
                                                                class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                                <label class="reg-fn mb-2 d-sm-none">
                                                                Ngân hàng</label>
                                                                <select name="bank" class="form-control form-select" v-model="formData.bank_id">
                                                                    <option value=0 selected>Ngân hàng</option>
                                                                    <option v-for="bank in listBank" :value="bank.id">
                                                                        {{ bank.shortName + '-' + bank.name }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="form-group d-flex">
                                                            <div class="col-5 d-none-mobie align-self-center">
                                                                <label class="">Số tài khoản ngân hàng</label>
                                                            </div>
                                                            <div
                                                                class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                                <label class="reg-fn mb-2 d-sm-none">
                                                                Số tài khoản ngân hàng</label>
                                                                <input class="form-control format-number"
                                                                    v-model="formData.bank_account_number" @input="__checkNumber"
                                                                    type="text" 
                                                                    placeholder="Nhập số tài khoản ngân hàng">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="form-group d-flex">
                                                            <div class="col-5 d-none-mobie align-self-center">
                                                                <label class="">Chi nhánh </label>
                                                            </div>
                                                            <div
                                                                class="form-check d-md-flex align-items-center col-md-7 col-xs-12">
                                                                <label class="reg-fn mb-2 d-sm-none">
                                                                Chi nhánh </label>
                                                                <input class="form-control format-number" name="branch"
                                                                    v-model="formData.branch" @input="inputTxt"
                                                                    type="text" placeholder="Nhập chi nhánh ngân hàng">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <b-form-radio-group >
                                                            <b-form-checkbox class="d-flex align-item-center check_form" v-model="checkForm" >
                                                                <div>
                                                                    Tôi đồng ý với những điều khoản của công ty
                                                                </div>
                                                            </b-form-checkbox>
                                                        </b-form-radio-group>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-12 p-0 flex-center my-2">
                                                <button class="btn-gold col-md-6" type="submit" :disabled="!checkForm">
                                                    ĐĂNG KÝ TẠO TÀI KHOẢN</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-12 p-0 mt-5" v-else>
                                <div style="margin: 0 auto; max-width: 400px;">
                                    <span class="title_form">Nhập số điện thoại để đăng ký tài khoản của bạn. Hệ thống sẽ gửi mã OTP đến số điện thoại của bạn để đăng ký tài khoản. Xin cảm ơn!!</span>
                                    <b-form class="mt-2" id="form-login-member" @submit="checkFormRes()" v-if="!verifiOTP">
                                        <div class="form-group my-3">
                                            <input class="form-control" type="text"
                                                placeholder="Nhập số điện thoại" required maxlength="10"
                                                v-model="formOTP.phone" style="margin-bottom: 15px;" @input="isValidPhoneNumber(formOTP.phone)" :disabled="formCheck">
                                            <div class="invalid-feedback d-block mb-2" v-if="formOTP.phone && !isValidPhoneNumber(formOTP.phone)">Vui lòng nhập số điện thoại hợp lệ </div>
                                        </div>

                                        <div v-if="verifiEmail">
                                            <div class="form-groupn mb-3 d-flex">
                                                <div v-for="(input, index) in pinLength" :key="index" class="col-xl-2">
                                                    <input class="otp-input form-control" 
                                                        type="text" 
                                                        maxLength="1" 
                                                        @keyup="$event => moveToNext($event, index)" 
                                                        @keydown="$event => removeToNext($event, index)"
                                                        v-model="otp_numbers[index]"
                                                        style="border-radius: 10px; text-align: center;"
                                                    />
                                                </div>
                                            </div>
                                            <div class="d-flex flex-end mb-3">
                                                <b-link class="forgot_password" @click="sendOTP" :disabled="countdown != 0" href="">Gửi lại mã ({{ formatCountdown(countdown) }}s)</b-link>
                                            </div>
                                        </div>
                                        <button class="btn btn-gold" type="submit" :disabled="!formOTP.phone || !isValidPhoneNumber(formOTP.phone) || isLoading">
                                            XÁC NHẬN
                                        </button>
                                    </b-form>
                                </div>
                            </div> -->
                        </div>
                    </b-row>
                </div>
            </div>
        </div>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script >
import Vue from "vue";
import auth from './auth'
Vue.mixin(auth)
export default {
    title: "Đăng kí",
    components: {

    },
    data() {
        return {
            formData: {
                type: 1,
                member_name: "",
                rep_name: "",
                position: "",
                phone: "",
                email: "",
                password: "",
                birthday:null,
                birthday_y: '',
                birthday_m: '',
                birthday_d: '',
                rep_address: "",
                province_id: 0,
                district_id: 0,
                ward_id: 0,
                member_address: "",
                id_number: "",
                id_date: null,
                id_place: "",
                tax_code: "",
                tax_date: null,
                tax_place: "",
                gender: '0',
                id_back_side: "",
                id_front_face: "",
                business_registration: "",
                file_path: [],
                bank_account_name: "",
                bank_id: 0,
                bank_account_number: "",
                branch: "",
                
            },
            publicKey: 'MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHvcIWyQskFcrDyzUuVML4OgVCrbOS+g0K5B5ngrMK1623+eBYBVegi6f0fe15xNatSphlBNjaTezEyZVYORd9lRfxMglWXeUtDTQhqmzBU4653USZZqdMGBdPMZRpDXO4+T+WEEXLDA/bW1MZ8E8LIKof2edlwAGX2JBKDqwPfhAgMBAAE=',
            checkForm:'',
            iconStt: true,
            iconSttRepass: true,
            rePassword: '',
            sendPassword:"",
            dataCity: [],
            dataDistricts: [],
            dataWard: [],
            max: new Date(),
            valueDay: 31,
            valueMonth: '',
            valueYear: new Date().getFullYear(),
            years: [],
            listBank : [],
            dataMember:{},
            encryptedData: '',
            duplicateEmail:'',
            duplicatePhone:'',
            duplicateTax: '',
            duplicateId: '',
            duplicateBirthDay: '',
            formOTP: {
                phone:'',
            },
            pinLength: 6,
            otp_numbers: [],
            countdown:300,
            formCheck: false,
            verifiEmail:false,
            verifiOTP:false,
            isLoading: false,
        }
    },
    computed: {
        otp() {
            return this.otp_numbers.join('')
        },
    },
    methods:{
        moveToNext(e, index) {
            if ((index + 1) != this.pinLength && e.key != 'Backspace') {
                document.getElementsByClassName('otp-input')[index + 1].focus()
            }
        },
        removeToNext(e,index){
            if (index !== 0 && e.key == 'Backspace') {
                if(e.target.value) {
                    document.getElementsByClassName('otp-input')[index].focus()
                } else {
                    document.getElementsByClassName('otp-input')[index - 1].focus()
                }
            }
        },
        async checkFormRes() {
            event.preventDefault()
            if (!this.verifiEmail) {
                this.sendOTP()
            }else{
                this.checkOTP()
            }            
        },
        startCountdown() {
            if (this.countdown <= 0) {
                this.countdown = 0
            } else {
                this.countdown--
            }
        },
        async sendOTP(){
            await this.$axios.post('/member/sendOTPRegister', this.formOTP).then((res) => {
                // this.$toast.success(res.data.message)
                this.verifiEmail = this.formCheck = true
                clearInterval(this.checkTimeOut)
                this.countdown = 300
                this.checkTimeOut = setInterval(this.startCountdown, 1000)
            }).catch((err) => {
                if (err.code == "ERR_NETWORK") {
                    this.$toast.error('Lỗi hệ thống')
                } else {
                    this.$toast.error(err.response.data.error)
                }
            })
        },
        async checkOTP(){
            const data = {
                "otp": this.otp
            }
            await this.$axios.post('/member/validOTPRegister', data).then((res) => {
                this.verifiOTP = true
            }).catch((err) => {
                if (err.code == "ERR_NETWORK") {
                    this.$toast.error('Lỗi hệ thống')
                } else {
                    this.$toast.error(err.response.data.error)
                }
            })
        },
        inputTxt() {
            let name = event.target.name
            this.formData[name] = this.formData[name].replace(/[<>\[\]]/g, '')
        },
        async checkDuplicateEmail(){
            await this.$axios.post('/common/checkDuplicateEmail', {
                email: this.formData.email
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                this.duplicateEmail = ''
            }).catch(err => {
                this.duplicateEmail = err.response.data.error
            })
        },
        async checkDuplicatePhone() {
            if (this.formData.phone.length < 10) {
                this.duplicatePhone = 'Số điện thoại không hợp lệ'
            }else{
                await this.$axios.post('/common/checkDuplicatePhone', {
                    phone: this.formData.phone
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then(res => {
                    this.duplicatePhone = ''
                }).catch(err => {
                    this.duplicatePhone = err.response.data.error
                })
            }
        },
        checkBirthDay() {
            this.formData.birthday = this.formData.birthday_y + "-" + this.formData.birthday_m + '-' + this.formData.birthday_d
            if (this.formData.birthday_y && this.formData.birthday_m && this.formData.birthday_d) {
                if (new Date(this.formData.birthday) >= new Date()) {
                    this.duplicateBirthDay = 'Ngày sinh không hợp lệ'
                    this.formData.birthday_y = ''
                    this.formData.birthday_m = ''
                    this.formData.birthday_d = ''
                }
                else{
                    this.duplicateBirthDay = ''
                }
            }
        },
        async checkDuplicateId() {
            await this.$axios.post('/common/checkDuplicateId', {
                id_number: this.formData.id_number
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                this.duplicateId = ''
            }).catch(err => {
                this.duplicateId = err.response.data.error
            })
        },
        async checkDuplicateTax() {
            await this.$axios.post('/common/checkDuplicateTax', {
                tax_code: this.formData.tax_code
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                this.duplicateTax = ''
            }).catch(err => {
                this.duplicateTax = 'Mã số doanh nghiệp / mã số thuế đã được đăng kí '
            })
        },
        async __register(params) {
            if (this.rePassword != this.sendPassword) {
                this.rePassword = false
            }
            if (params.phone.length < 10) {
                params.phone = ''
            }
            // params.phone = this.formOTP.phone
            params.password = this.encryptData(this.sendPassword)
            this.isLoading = true
            await this.$axios.post('/member/createMember', params, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                this.$toast.success('Bạn đã đăng ký tài khoản thành công xin vui lòng chờ tối đa 3 ngày để duyệt hoặc liên hệ theo hotline công ty để được hỗ trợ.')
                this.$router.push('/login')
            }).catch(err => {
                if (err.code == "ERR_NETWORK") {
                    this.$toast.error('Lỗi hệ thống')
                } else {
                    this.$toast.error(err.response.data.error)
                }
            })
            this.isLoading = false
        },
    },
    mounted() {
        this.$axios.get('/common/getListProvince')
        .then(res => {
            this.dataCity = res.data.province
        })
        .catch(error => {
        });
        this.generateYearList();
        this.getListBank();
    },
}

</script>
<style scoped lang="css">
.auth .review-img {
    height: auto;
    width: 170px;
    margin-top: 0.5rem;
    cursor: pointer;
}

.gender {
    display: flex;
    width: 100%;
}

.gender .custom-radio {
    display: flex;
    align-items: center;
    width: 115px;
}
.b-form-btn-label-control.form-control{
    height: 45px;
    display: flex;
    align-items: center;
}

.form-control:disabled {
    opacity: 0.5;
    cursor: no-drop;
    background-color: unset;
}

.title_form {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 1rem;
    width: 421px;
    max-width: 100%;
    display: block;
    line-height: 23px;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .auth .logo-login {
        justify-content: center;
        margin-bottom: 30px;
    }
    .auth .banner-main .logo-login img{
        max-width: 300px;
    }
}
@media (max-width: 768px) {
    .auth .logo-login {
        justify-content: center;
        margin-bottom: 30px;
    }
}

@media (max-width: 425px) {
    .auth .logo-login img {
        width: 100%;
    }

    .gender {
        justify-content: space-around;
    }
}
</style>
