<template>
    <div>
        <h5>Tạo quảng cáo mới</h5>
        <b-form class="form-add-ads">
            <b-row>
                <b-col cols="lg-8">
                    <p class="p_ttl">Thông tin đối tác</p>
                    <b-row class="align-items-center mb-3">
                        <b-col cols="md-3">
                            <label for="name">Tên đối tác:</label>
                        </b-col>
                        <b-col cols="md-9">
                            <b-input type="text" id="name" placeholder="Nhập tên công ty, cá nhân"></b-input>
                        </b-col>
                    </b-row>

                    <b-row class="align-items-center mb-3">
                        <b-col cols="md-3">
                            <label for="phone">Số điện thoại liên hệ:</label>
                        </b-col>
                        <b-col cols="md-9">
                            <b-input type="text" id="phone" placeholder="Nhập số điện thoại"></b-input>
                        </b-col>
                    </b-row>

                    <b-row class="align-items-center mb-3">
                        <b-col cols="md-3">
                            <label for="email">Email:</label>
                        </b-col>
                        <b-col cols="md-9">
                            <b-input type="email" id="email" placeholder="Nhập email"></b-input>
                        </b-col>
                    </b-row>

                    <p class="p_ttl mb-4">Thông tin quảng cáo</p>
                    <b-row class="mb-3">
                        <b-col cols="md-3">
                            <p>Banner quảng cáo:</p>
                        </b-col>
                        <b-col cols="md-9">
                            <img :src="this.dataImg" v-if="this.dataImg" style="max-width: 308px; border-radius: 10px; margin-right: 20px;" onclick="$('#file_img').click()">
                            <img src="@/assets/admin/icon/icon_add_ads.png" alt="" onclick="$('#file_img').click()" v-else>
                            <b-form-file class="mt-3 d-none"  id="file_img" ref="fileInput" @change="handleImgChange"
                            accept="image/png, image/gif, image/jpeg, image/jpg"></b-form-file>
                        </b-col>
                    </b-row>

                    <b-row class="align-items-center mb-3">
                        <b-col cols="md-3">
                            <label for="link">Link liên kết:</label>
                        </b-col>
                        <b-col cols="md-9">
                            <b-input type="text" id="link" placeholder="Nhập Link liên kết"></b-input>
                        </b-col>
                    </b-row>

                </b-col>
                <b-col cols="lg-4">
                    <p class="p_ttl">Thông tin hợp đồng</p>
                    <b-row class="align-items-center mb-3">
                        <b-col cols="md-4">
                            <label for="start_time">Ngày bắt đấu:</label>
                        </b-col>
                        <b-col cols="md-8">
                            <b-form-datepicker id="start_time" start-weekday=1 placeholder="Chọn ngày bắt đấu quảng cáo" locale="vi"></b-form-datepicker>
                        </b-col>
                    </b-row>

                    <b-row class="align-items-center mb-3">
                        <b-col cols="md-4">
                            <label for="end_time">Ngày kết thúc:</label>
                        </b-col>
                        <b-col cols="md-8">
                            <b-form-datepicker id="end_time" start-weekday=1 placeholder="Chọn ngày kết thúc quảng cáo" locale="vi"></b-form-datepicker>
                        </b-col>
                    </b-row>

                    <b-row class="align-items-center mb-3">
                        <b-col cols="md-4">
                            <p>Vị trí hiện:</p>
                        </b-col>
                        <b-col cols="md-8">
                            <p style="font-weight: 500;">Chi tiết tin tức</p>
                        </b-col>
                    </b-row>

                    <b-row class="align-items-center mb-3">
                        <b-col cols="md-4">
                            <p>Thứ tự hiện:</p>
                        </b-col>
                        <b-col cols="md-8">
                            <p style="font-weight: 500;">Random</p>
                        </b-col>
                    </b-row>
                    <div>
                        <img src="@/assets/icon/Note.png" alt="">
                        <b-link>Hợp đồng quảng cáo</b-link>
                    </div>
                </b-col>

                <div class="text-right mt-5">
                    <button>Hủy bỏ</button>
                    <button>Lưu bản nháp</button>
                    <button class="send">Lưu quảng cáo</button>
                </div>
            </b-row>
        </b-form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dataImg: ''
        }
    },
    methods: {
        handleImgChange(event) {
            const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = () => {
                    this.dataImg = reader.result
                };
                reader.readAsDataURL(file);
            }
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        font-family: Roboto;
        color: #515151;
        font-size: 22px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 15px;
    }

    .form-add-ads {
        font-family: Roboto;
        box-shadow: 2px 2px 6px 0px #00000026;
        background-color: #FFFFFF;
        border-radius: 15px;
        padding: 18px 29px 23px 29px;
        color: #515151;
        background-image: url('@/assets/images/logo_moreinfo.png');
        background-repeat: no-repeat;
        background-position: center;
    }

    p.p_ttl {
        font-size: 20px;
        font-weight: 500;
    }
    input.form-control {
        background-color: #EEEEEE;
        border-radius: 15px;
        height: 49px;
        border: none;
    }

    input.form-control:focus {
        box-shadow: none;
        border: none;
    }

    input.form-control::placeholder {
        font-weight: 500;
        color: #929292;
    }

    button {
        color: #EE3439;
        border: 1px solid #EE3439;
        border-radius: 20px;
        background: none;
        font-size: 18px;
        font-weight: 500;
        width: 160px;
        height: 42px;
        margin-right: 10px;
        margin-top: 15px;
    }

    button.send {
        margin-right: 0;
        color: #FFFFFF;
        background-color: #EE3439;
        width: 203px;
    }
    a {
        color: #515151;
        font-weight: 500;
        margin-left: 7px;
    }
</style>


<style>
    .form-add-ads button.h-auto {
        opacity: 0;
    }

    .form-add-ads .no-gutters {
        margin: 0;
    }

    .form-add-ads .b-calendar-grid-help {
        display: none;
    }

    .form-add-ads .b-calendar .form-control:focus,
    .form-add-ads .b-form-datepicker.form-control.focus,
    .form-add-ads .form-control.focus {
        box-shadow: none;
        border: 1px solid #ced4da6e;
    }

    .form-add-ads .text-truncate {
        padding: 0;
    }

    .form-add-ads .b-form-datepicker,
    .form-add-ads .b-form-timepicker {
        background-color: #EEEEEE;
        border-radius: 12px;
        padding: 2px 6px;
    }

    .form-add-ads .text-muted {
        color: #B4B4B4 !important;
    }
</style>