<template>
    <div>
        <b-modal id="authority" ref="authority" centered hide-footer hide-header content-class="modal-width" 
            style="width: 421;height: 342px;" :modal-class="'modal-join step-'+step">
            <button class="d-none btn-close" @click="$refs['authority'].hide()"></button>
            <form class="authority" @submit.prevent="submitForm()" v-if="step == '1' || step == '2'">
                <img src="../../../assets/icon/icon_auction.png" alt="">
                <h5>Ủy quyền tham gia đấu giá</h5>
                <div v-if="step=='1'">
                    <p>Bạn đang muốn ủy quyền cho cá nhân hoặc tổ chức tham gia đấu giá và trả giá <br>
                        Hãy nhập đủ thông tin bên dưới </p>
                    <div class="text-red">
                        Lưu ý: Khi đã xác nhận ủy quyền, bạn vẫn được tham gia vào phòng đấu giá nhưng không được trả giá.
                        Nếu người được ủy quyền trúng đấu giá, 
                        thông tin của bạn và người được ủy quyền sẽ được ghi vào biên bản đấu giá, bạn sẽ là người trúng đấu giá
                    </div>
                    <div class="form-gr">
                        <label for="">Email người được ủy quyền</label>
                        <b-form-input type="email" required placeholder="Nhập Email" v-model="formData.email"></b-form-input>
                        <p style="color: #EE3439; font-weight: 600; margin: 10px 0 0;" v-if="showError">{{ messageError }}</p>
                    </div>
                </div>
                <div v-if="step == '2'">
                    <p>Bạn đang muốn ủy quyền cho cá nhân hoặc tổ chức tham gia đấu giá và trả giá <br>
                    Hãy xác nhận người ủy quyền  </p>
                    <div class="form-gr">
                        <label for="">Người được ủy quyền</label>
                        <b-form-input type="text" required placeholder="Nhập mã key" v-model="formData.member_name"></b-form-input>
                    </div>
                    <div class="form-gr">
                        <label for="">Giấy / hợp đồng ủy quyền</label>
                        <div class="choose-file">
                            <button type="button" onclick="$(this).next().click()">
                            Chọn file</button>
                            <input type="file" name="authorization_file" class="d-none" accept="application/pdf" 
                            ref="image" multiple @change="uploadReview()">
                            <button type="button" class="btn-input-file" onclick="$(this).prev().click()">{{ fileName }}</button>
                        </div>
                    </div>
                </div>
                <b-button class="mt-3 cancel_btn" type="button" v-if="step == '1'" block @click="hide">Hủy bỏ</b-button>
                <b-button class="mt-3 cancel_btn" type="button" v-if="step == '2'" block @click="step = '1'">Hủy bỏ</b-button>
                <b-button class="mt-3 confirm" block type="submit" v-if="step=='1'">Tiếp theo</b-button>
                <b-button class="mt-3 confirm" block type="submit" v-else>Xác nhận</b-button>
            </form>
            <div class="statusAutho text-center" v-else>
                <div v-if="step == '3'">
                    <img src="@/assets/icon/Ok.png" style="width: 63px; height: 63px;" alt="">
                    <p class="label">Thành công</p>
                    <p>Bạn đã ủy quyền tham gia đấu giá</p>
                </div>
                <div v-else>
                    <img src="@/assets/front-end/icon/Group1370.png" 
                    style="width: 87px; height: 87px; margin-bottom: 10px;" alt="">
                    <p class="label" style="margin-bottom: 23px;"> Đã gửi ủy quyền tham gia đấu giá</p>
                    <p style="line-height: normal;">Bạn đã yêu cầu ủy quyền cho người khác tham gia đấu giá. Hãy đợi quản trị viên kiểm duyệt và gửi thông báo </p>
                </div>
            </div>
        </b-modal>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showError: false,
            userCurrent: '',
            auction_id: '',
            asset_id: '',
            auction_authority_status: 0,
            messageError: "Email không hợp lệ",
            step: '1',
            fileName:'File PDF',
            isLoading: false,
            formData:{
                email: '',
                member_name: '',
                authorization_file:'',
                member_id:'',
            }
        }
    },
    created() {
        this.userCurrent = localStorage.getItem('member');
    },
    methods: {
        submitForm() {
            if(this.step == '1'){
                this.$axios.post('/auction/checkEmail', {
                    email: this.formData.email,
                    auction_id: this.auction_id,
                }, {
                    headers: {
                        'x-access-token': localStorage.getItem('member')
                    }
                })
                .then(res => {
                    if(res.data.member_name && res.data.member_id){
                        this.formData.member_name = res.data.member_name
                        this.formData.member_id = res.data.member_id
                        this.step = '2'
                    }else{
                        this.showError = true
                    }
                })
                .catch(error => {
                    this.checkAuthenticate(error)
                    this.showError = true
                });
            }else if (this.step == '2') {
                this.$axios.post('/auction/auctionAuthorization', {
                    auction_id: this.auction_id,
                    member_id: JSON.parse(localStorage.getItem('userInfo')).id,
                    asset_id: this.asset_id,
                    authorization_file: this.formData.authorization_file,
                    authorized_person: this.formData.member_id,
                }, {
                    headers: {
                        'x-access-token': localStorage.getItem('member')
                    }
                })
                .then(res => {
                    if (this.auction_authority_status) {
                        this.step = 4
                    }else{
                        this.step = 3
                    }
                })
                .catch(error => {
                    this.checkAuthenticate(error)
                    if (error.response) {
                        this.$toast.warning(error.response.data.error)
                    }
                    this.showError = true
                });
            }
        },
        show(dataAsset) {
            this.$refs['authority'].show()
            this.auction_id = dataAsset.auction_id
            this.asset_id = dataAsset.asset_id
            this.auction_authority_status = dataAsset.auction_authority_status
        },
        hide() {
            this.$refs['authority'].hide()
            this.step = '1'
            this.showError = false
        },
        async uploadReview(){
            this.isLoading = true
            let name = event.target.name
            await this.$axios.post('/common/uploadFile', {
                file: event.target.files[0],
                path: 'member/authorization_file_file/',
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                this.isLoading = false
                this.formData[name] = res.data.list_result[0].path
                this.fileName = this.getFileName(res.data.list_result[0].path_tam)
            }).catch(err => {
                this.isLoading = false
            })
        },
        getFileName(fileName) {
            let arr = fileName.split("-")
            return arr[arr.length - 1];
        },
    }
}
</script>
<style lang="css">
#authority .modal-width {
    width: 421px;
    min-height: 342px;
    padding: 0;
}

.authority {
    text-align: center;
    color: #444444;
}

.authority h5 {
    font-weight: 500;
    font-size: 20.96px;
    line-height: 37.73px;
}

.authority button {
    width: 165.06px;
    height: 41.9px;
    border-radius: 32px;
    font-weight: 700;
    font-size: 16px;
    border: none;
}

.authority .confirm,
.authority .confirm:active {
    background: #EE3439;
}

.authority input {
    border-radius: 20px;
    background: #EEEEEE;
    border: none;
    height: 44px;
}

.authority input:focus {
    box-shadow: none;
    background: #EEEEEE;
}

.authority .cancel_btn,
.authority .cancel_btn:active {
    background: #FFFFFF;
    border: 1px solid #EE3439;
    color: #EE3439;
    margin-right: 10px;
}
.modal-dialog-centered{
    justify-content: center;
}
.authority .text-red{
    color: #EE3439;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
}
.authority .form-gr{
    text-align: left;
    margin-top: 16px;
}
.authority .form-gr label{
    color: #515151;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
}
.choose-file{
    position: relative;
    display: flex;
    margin-bottom: 0.5rem;
    align-items: center;
}
.choose-file button{
    width: 81px;
    height: 40px;
    border-radius: 20px;
    background: #444;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: unset;
    justify-content: center;
}
.choose-file button.btn-input-file{
    width: 312px;
    height: 44px;
    border-radius: 20px;
    background: #F6F6F6;
    color: #515151;
    font-size: 16px;
    font-weight: 400;
    border: unset;
    justify-content: flex-start;
    padding: 0 14px;
    margin-left: 10px;
}
.choose-file input{
    margin-left: 7px;
    border-radius: 15px;
    background: #F6F6F6;
    color: #515151;
    font-size: 16px;
    font-weight: 400;
    height: 44px;
    padding: 5px 10px;
}
.statusAutho{
    color: #444;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
}
.statusAutho .label{
    color: #444;
    font-family: Roboto;
    font-size: 20.96px;
    font-weight: 500;
    margin-bottom: 10px;
}
#authority.step-3 .modal-width{
    width: 319px;
    height: 213px;
    min-height: unset;
}
#authority.step-3 .statusAutho{
    padding: 41px 20px 20px 20px;
}
#authority.step-4 .btn-close{
    display: block !important;
    position: absolute;
    top: 5px;
    right: 5px;
}
#authority.step-4 .modal-body{
    padding: 20px 17px;
}
#authority.step-4 .modal-width{
    width: 373px;
    height: 251px;
    min-height: unset;
}
</style>