<template>
    <div>
        <b-modal id="confirm_pay" ref="confirm_pay" centered hide-footer hide-header @hide="">
            <div class="confirm_pay">
                <img src="@/assets/icon/Auction_modal.png" alt="">
                <h5>Trả giá tài sản với: {{ formattedValue }} VNĐ</h5>
                <p>Giá trả của bạn: {{ numberInWords }} đồng</p>
                <p class="important">Lưu ý: Khi bạn đã xác nhận giá trả sẽ không thể chỉnh sửa giá được nữa</p>
                <b-button class="mt-3 btn-cancel" block @click="$bvModal.hide('confirm_pay')">Hủy bỏ</b-button>
                <b-button class="mt-3" block @click="confirmPay" v-b-modal.success_pay>Xác nhận giá trả</b-button>
            </div>
        </b-modal>
        <b-modal id="success_pay" ref="success_pay" centered hide-footer hide-header>
            <div class="success_pay text-center" v-if="message.result_status = 1">
                <img src="@/assets/icon/Ok.png" alt="">
                <p style="color: #00B569; font-weight: 500; font-size: 23.33px;">Thành công</p>
            </div>
            <div class="warning text-center" v-else>
                <img src="@/assets/icon/Ok.png" alt="">
                <p style="color: #EE3439; font-weight: 500; font-size: 23.33px;">{{ this.message.message }}</p>
            </div>
        </b-modal>
    </div>
</template>

<script>
// import VNnum2words from 'vn-num2words';
import number2vn from 'number2vn';
export default {
    props: ["price"],
    data() {
        return {
            data: this.price,
            message: {
                result_status: 0,
                message: ''
            }
        }
    },
    computed: {
        numberInWords() {
            // return VNnum2words(this.data.price).replace('muời', 'mười');
            return number2vn(this.data.price);
        },
        formattedValue() {
            let value = parseInt(this.data.price);
            return value.toLocaleString("vi-VN");
        },
    },
    methods: {
        show(message) {
            this.message = message
            this.$refs['confirm_pay'].show()
        },
        confirmPay() {
            this.$refs['confirm_pay'].hide()
            this.$refs['success_pay'].show()
            this.$emit('submit-form');
        }
    }
}
</script>

<style lang="css">
.modal-content {
    border-radius: 20px;
    box-shadow: 0px 0px 38px 0px #00000040;

}

.confirm_pay {
    color: #444444;
    text-align: center;
}

.confirm_pay h5 {
    font-weight: 500;
    font-size: 20.96px;
    line-height: 37.73px;
}

.confirm_pay p {
    font-weight: 400;
    font-size: 17.27px;
    line-height: 31.08px;
}

.confirm_pay .important {
    font-size: 16.59px;
    line-height: 19.45px;
    color: #EE3439;
    font-weight: 400;
    text-align: left;
    padding-left: 47px;
}

.confirm_pay .important::before {
    content: "";
    position: absolute;
    background-image: url(@/assets/icon/Important.png);
    background-repeat: no-repeat;
    width: 24px;
    height: 20px;
    left: 37px;
}

.confirm_pay button {
    background: #EE3439;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14.47px;
    width: 165px;
    height: 42px;
    font-family: Roboto;
    border-radius: 9.65px
}

.confirm_pay .btn-cancel {
    color: #EE3439;
    background: transparent;
    border: 1px solid #EE3439;
    margin-right: 10px;
}

.confirm_pay button:hover {
    background: #EE3439;
}

.confirm_pay .btn-cancel:hover {
    background: transparent;
}

@media (max-width: 425px) {
    .confirm_pay p {
        font-size: 15.27px;
    }
}

@media (max-width: 375px) {
    .confirm_pay h5 {
        font-size: 14.96px;
    }

    .confirm_pay p {
        font-size: 13.27px;
    }

    .confirm_pay .important {
        font-size: 14.59px;
        padding-left: 20px;
    }

    .confirm_pay .important::before {
        left: 9px;
    }
}
</style>
