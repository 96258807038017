<template>
    <div>
        <b-modal v-model="showModal" ref="modalEnd" centered hide-footer hide-header content-class="modal-width"
            modal-class="modal-endw" id="modalEnd">
            <button @click="$refs.modalEnd.hide()" class="close-btn">x</button>
            <div class="accept-modal text-center">
                <img src="@/assets/front-end/icon/Group1370.png" alt="">
                <h5 style="margin-top: 10px;">Thời gian trả giá kết thúc</h5>
                <p>Thời gian trả giá đã kết thúc, vui lòng đợi trong ít phút để đấu giá viên kiểm tra kết quả</p>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: ['dataProp'],
    data() {
        return {
            showModal: this.dataProp,
            auction_id: '',
            asset_id: '',
            auction_key: '',
            info: JSON.parse(localStorage.getItem('auInfo'))
        }
    },
    created() {
        this.auctionId = this.decode(this.$route.params.id);
        this.getData()
    },
    methods: {
        async getData() {
            const res = await this.$axios.get('/asset/getAssetDetail', {
                params: {
                    asset_id: this.auctionId
                },
                headers: {
                    'x-access-token': localStorage.getItem('member')
                }
            })

            if (res.status == 200) {
                this.asset_id = res.data.asset_id
                this.auction_key = res.data.auction_key;
            }
        },
        endRoom() {
            this.$bvModal.hide('modalEnd')
        },
        show(){
            this.$refs.modalEnd.show()
            setTimeout(() => {
                this.$bvModal.hide('modalEnd')
            }, 10000);
        },
        hide(){
            this.$bvModal.hide('modalEnd')
        }
    }
}
</script>
<style>
.modal-endw .modal-width {
    width: 370px;
}
</style>
<style lang="css" scoped>
.modal-content {
    border-radius: 20px;
    box-shadow: 0px 0px 38px 0px #00000040;

}
.close-btn:hover{
    background-color: unset !important;
}
.modal-endw button:active,
.modal-endw button:focus {
    background-color: #EE3439 !important;
}

.modal-endw button:hover {
    background-color: #EE3439;
}
</style>