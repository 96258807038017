<template>
    <div class="container payment-history">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="col-12 mb-2">
            <div class="title-1 mb-3">
                Lịch sử trả giá:
            </div>
            <div class="title-2 mr-3">
                Quyền sử dụng đất cùng tài sản gắn liền với đất tại dự án 213
            </div>
        </div>
        <div class="background-image w-100">
            <img src="@/assets/front-end/img/logo 4.png" alt="">
        </div>
        <div class="col-12">
            <div class="table-responsive tab-product-detail">
                <table role="table" aria-busy="false" aria-colcount="5" class="table b-table" id="__BVID__71">
                    <thead role="rowgroup" class="">
                        <tr role="row" class="col-12">
                            <th role="columnheader" scope="col" aria-colindex="1" class="text-center col-md-3">
                                <div>Tên tài sản</div>
                            </th>
                            <th role="columnheader" scope="col" aria-colindex="2" class="text-center col-md-1">
                                <div>Mã tài sản</div>
                            </th>
                            <th role="columnheader" scope="col" aria-colindex="3" class="text-center col-md-1">
                                <div>Mã của bạn</div>
                            </th>
                            <th role="columnheader" scope="col" aria-colindex="4" class="text-center col-md-2">
                                <div>Giá của bạn</div>
                            </th>
                            <th role="columnheader" scope="col" aria-colindex="5" class="text-center col-md-2">
                                <div>Giá trúng đấu giá</div>
                            </th>
                            <th role="columnheader" scope="col" aria-colindex="6" class="text-center col-md-2">
                                <div>Kết quả</div>
                            </th>
                            <th role="columnheader" scope="col" aria-colindex="7" class="text-center col-md-1">
                            </th>
                        </tr>
                    </thead>
                    <tbody role="rowgroup">
                        <tr role="row" class="my-2" v-for="(dataTable, index) in data">
                            <td aria-colindex="1" role="cell" class="text-center col-md-3">{{ dataTable.name }}</td>
                            <td aria-colindex="2" role="cell" class="text-center col-md-1">{{ dataTable.asset_code }}</td>
                            <td aria-colindex="3" role="cell" class="text-center col-md-1">{{ dataTable.member_code }}</td>
                            <td aria-colindex="4" role="cell" class="text-center col-md-2">{{ dataTable.price }}</td>
                            <td aria-colindex="1" role="cell" class="text-center col-md-2">{{ dataTable.end_price }}</td>
                            <td aria-colindex="5" role="cell" class="col-md-2" v-if="dataTable.status == 1">
                                <img src="@/assets/front-end/icon/Ok.png" alt=""
                                    style="width: 20px; height: 20px;margin-right: 10px;">
                                <div>
                                    Trúng đấu giá
                                </div>
                            </td>
                            <td aria-colindex="5" role="cell" class="col-md-2" v-else>
                                <img src="@/assets/front-end/icon/out_close.png" alt=""
                                    style="width: 20px; height: 20px;margin-right: 10px;">
                                <div>
                                    Không trúng đấu giá
                                </div>
                            </td>
                            <td aria-colindex="5" role="cell" class="col-md-1" v-if="dataTable.payment_status == 0">
                                <button class="btnn btn-warning">Xác nhận</button>
                            </td>
                            <td aria-colindex="5" role="cell" class="col-md-1" v-else>
                                <span class="success">Đã xác nhận</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import Table from "../components/table.vue"
import Pagination from "../components/paginate.vue";

export default {
    components: {
        Table,
        Pagination
    },
    data() {
        return {
            data: [
                {
                    name: 'Quyền sử dụng đất cùng tài sản gắn liền với đất tại dự án 213',
                    asset_code: 'Dickerson',
                    member_code: 'Dickerson',
                    price: '7.310.000.000 VNĐ',
                    end_price: '02/04/2023 09:27:37',
                    status: 1,
                    payment_status: 1
                }
            ],
            items: [
                {
                    text: 'Trang chủ',
                    href: '/',
                },
                {
                    text: 'Quản lý tài khoản',
                    href: '/account'
                },
                {
                    text: 'Lịch sử trả giá',
                    active: true
                },
            ],
        }
    }
}
</script>
<style scoped lang="css">
.payment-history .table {
    font-family: Roboto;
}

.tab-product-detail tr {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 53px;
}

.tab-product-detail thead tr {
    background-color: #E5E5E5;
    border-radius: 10px;
}

.tab-product-detail tbody tr {
    border: #ABABAB 1px solid;
    border-radius: 25px;
}

.tab-product-detail tr td {
    border-bottom-width: 0;
}

.payment-history {
    color: #515151;
    min-height: 900px;
    font-family: Libre Bodoni;
}

.table td.col-md-2 {
    display: flex;
    align-items: center;
}

.payment-history .title-1 {
    font-weight: 500;
    font-size: 24px;
    height: 30px;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
}

.payment-history .title-2 {
    font-weight: 700;
    font-size: 20px;
    height: 36px;
    align-items: center;
    display: flex;
}

.payment-history span {
    font-size: 15px;
    font-family: Roboto;
    font-weight: 500;
}

@media (max-width:525px) {
    .table-responsive table {
        font-size: 0.5rem;
    }

    .table-responsive td.col-md-2 {
        font-size: 0.5rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .tab-product-detail tr {
        height: unset;
    }
}
</style>