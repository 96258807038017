<template>
    <div>
        <b-modal refs="endAuction" id="end-success-auction" centered hide-footer hide-header>
            <div class="end-success-auction">
                <img src="@/assets/icon/Auction_modal.png" alt="">
                <h5>Cuộc đấu giá đã kết thúc thành</h5>
                <p style="font-size: 15px;">Tài sản đấu giá: Quyền sử dụng đất cùng tài sản gắn liền với đất tại dự án 213
                </p>
                <p>Giá trả của bạn: <span style="font-weight: 500;">7.530.000.000 VNĐ</span></p>
                <p class="cancel-auction">( Đã rút lại giá đã trả )</p>
                <p style="font-size: 18px; font-weight: 500;">Chúc mừng bạn là người trả cao nhất và trúng đấu giá</p>
                <p>Giá trúng đấu giá: <span style="color: #EE3439; font-weight: 600;">7.480.000.000 VNĐ</span></p>
                <p>Vì đã rút lại giá đã trả nên bạn sẽ mất số tiền đặt trước </p>
                <p>Nhấn <span style="color: #00B569; font-weight: 500;">Xác nhận</span> để đồng ý kết quả đấu giá</p>
                <p>Nhấn <span style="color: #EE3439; font-weight: 500;">Từ chối</span> để hủy kết quả đấu giá</p>
                <p class="notice">Lưu ý: Bạn phải xác nhận trong khoản thời gian 299 giây, nếu không xác nhận hệ thống tự
                    động ghi nhận bạn chấp nhận kết quả cuộc đấu giá.</p>
                <b-button class="mt-3 denial_btn" block v-b-modal.accept_denial
                    @click="$bvModal.hide('end-success-auction')">TỪ CHỐI</b-button>
                <b-button class="mt-3 confirm" block v-b-modal.success-auction
                    @click="$bvModal.hide('end-success-auction')">Xác nhận</b-button>
            </div>
        </b-modal>

        <b-modal id="success-auction" centered hide-footer hide-header @hide="redirect()">
            <div class="success-auction">
                <img src="@/assets/icon/Ok.png" alt="">
                <h5>Thành công</h5>
                <p>Chúc mừng bạn đã là người trúng đấu giá</p>
            </div>
        </b-modal>

        <b-modal id="accept_denial" ref="accept_denial" centered hide-footer hide-header @hide="redirect()"
            content-class="success-auction">
            <button @click="$bvModal.hide('accept_denial')" class="close-btn">x</button>
            <div class="mx-3">
                <div class="end-success-auction" v-if="resultAuction.auction_status == 2">
                    <img src="@/assets/icon/Auction_modal.png" alt="">
                    <h5>Cuộc đấu giá đã kết thúc thành</h5>
                    <p style="font-size: 15px;">Tài sản đấu giá: {{ asset.asset_name }}
                    </p>
                    <p style="font-weight:500">
                        Chúc mừng quý khách {{ resultAuction.winner.member_code }} <br />
                        đã là người trúng đấu giá
                    </p>
                    <p>
                        <span>Giá trúng đấu giá:</span><span style="color: #EE3439; font-weight: 500;">
                            {{ parseInt(resultAuction.winner.price).toLocaleString("vi") }} VNĐ
                        </span>
                    </p>
                    <p><span>Thời điểm trả giá:</span><span>{{ formateTime(resultAuction.winner.time) }}</span></p>
                    <strong v-if="resultAuction.note">
                        Lý do trúng: {{ resultAuction.note }}
                    </strong>
                </div>
                <div class="end-success-auction" v-else-if="resultAuction.auction_status == 4">
                    <img src="@/assets/icon/Auction_modal.png" alt="">
                    <h5>Cuộc đấu giá đã kết thúc</h5>
                    <p style="font-size: 15px;">Tài sản đấu giá: {{ asset.asset_name }}
                    </p>
                    <p><span>Thời điểm kết thúc:</span><span>{{ formateTime(asset.end_time) }}</span></p>
                    <strong v-if="resultAuction.note">
                        Lý do: {{ resultAuction.note }}
                    </strong>
                </div>
                <div class="end-success-auction" v-else-if="resultAuction.destroyRoom">
                    <img src="@/assets/icon/Notice_ico.png" alt="">
                    <h5 style="font-size: 20px; font-weight: 500;">Cuộc đấu giá bị hủy</h5>
                    <p style="font-size: 16px; font-weight: 400;">Đấu giá viên đã dừng cuộc đấu giá, tài sản sẽ được đấu
                        giá lại trong một cuộc đấu giá khác, chúng tôi sẽ liên hệ với quý khách để hoàn lại các khoản phí đã thu.
                    </p>
                    <!-- <p style="font-size: 16px; font-weight: 400;">Lý do hủy:</p> -->
                    <p style="font-size: 16px; font-weight: 700;">{{ resultAuction.note }}</p>

                </div>
                <div class="end-success-auction" v-else>
                    <img src="@/assets/icon/Auction_modal.png" alt="">
                    <h5>Cuộc đấu giá đã kết thúc không thành</h5>
                    <p style="font-size: 15px;">
                        Tài sản đấu giá: {{ asset.asset_name }}
                    </p>
                    <strong>
                        Không có người trúng đấu giá<br> Cuộc đấu giá bị hủy bỏ
                    </strong>
                    <p>Giá trúng đấu giá:</p>
                    <p class="text-red">0 VNĐ</p>
                    <p></p>
                    <p><span>Thời điểm kết thúc:</span><span> {{ formateTime(asset.end_time) }}</span></p>
                    <strong>
                       {{ resultAuction.reason }} nên bị hủy bỏ <br>
                        Tài sản {{ asset.asset_code }} sẽ được đấu giá vào cuộc đấu giá khác.
                    </strong>
                </div>
            </div>
            <div class="d-flex mt-2 mb-2 text-left align-items-center">
                <img class="mr-2" style="width: 24px;height: 20px;" src="@/assets/front-end/icon/Important.png" alt="">
                <p class="warning m-0" style="color: #EE3439; font-weight: 500;">
                    Đã gửi thông báo tới tất cả khách hàng tham gia đấu giá </p>
            </div>
        </b-modal>
    </div>
</template>
<script>

export default {
    props: ['asset', 'resultAuction'],
    data() {
        return {
            inputValue: "",
            correct_code: false,
            asset_id: this.$route.params.id,
        };
    },
    mounted() {
        
    },
    computed: {
        random() {
            let randomString = '';

            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

            for (let i = 0; i < 5; i++) {
                const randomChar = characters.charAt(Math.floor(Math.random() * characters.length));
                randomString += randomChar;
            }
            return randomString;
        }
    },
    methods: {
        check_code() {
            if (this.inputValue == this.random) {
                this.$refs['accept_denial'].hide();
            } else {
                this.correct_code = true;
            }
        },
        redirect() {
        },
        hide(){
            this.$refs['accept_denial'].hide();
        },
        show() {
            this.$refs['accept_denial'].show();
            setTimeout(() => {
                if(this.$refs['accept_denial']){
                    this.$refs['accept_denial'].hide();
                }
            }, 30000);
        }
    }
}
</script>
<style lang="css">
.modal-content {
    border-radius: 20px;
    box-shadow: 0px 0px 38px 0px #00000040;
    
}
.end-success-auction .modal-width{
    left: 12%;
}
.close-btn {
    position: absolute;
    border: unset;
    background-color: #00000000;
    right: 1%;
    font-size: 22px;
    top: 0;
    color: #c5c5c5;
}

.success-auction .modal-body {
    padding: 1rem 1rem 0 1rem;
}

.end-success-auction,
.success-auction,
.accept_denial {
    text-align: center;
    color: #444444;
}

.end-success-auction h5,
.success-auction h5,
.accept_denial h5 {
    font-weight: 500;
    font-size: 20.96px;
    line-height: 37.73px;
}

.end-success-auction p {
    margin-bottom: 5px;
}

.end-success-auction .notice,
.end-success-auction .cancel-auction {
    color: #EE3439;
    font-size: 14px;
}

.end-success-auction .text-red {
    font-size: 16px;
    font-weight: 600;
    color: #EE3439;
}

.end-success-auction .notice {
    padding-left: 20px;
    text-align: left;
}

.end-success-auction .notice::before {
    content: "";
    position: absolute;
    background-image: url(@/assets/icon/Important.png);
    background-repeat: no-repeat;
    width: 24px;
    height: 20px;
    left: 9px;
}

.end-success-auction button,
.success-auction .confirm,
.accept_denial button {
    width: 165.06px;
    height: 41.9px;
    text-transform: uppercase;
    border-radius: 9.65px;
    font-weight: 700;
    font-size: 14.47px;
    border: none;
}

.end-success-auction .confirm,
.end-success-auction .confirm:active,
.success-auction .confirm,
.success-auction .confirm:active,
.accept_denial .confirm,
.accept_denial .confirm:active {
    background: #EE3439;
}

.end-success-auction .denial_btn,
.end-success-auction .denial_btn:active {
    background: #9C9C9C;
    margin-right: 32px;
}

.accept_denial input {
    border-radius: 9.65px;
    background: #EEEEEE;
    border: none;
}

.accept_denial input:focus {
    box-shadow: none;
    background: #EEEEEE;
}

.accept_denial .cancel_btn,
.accept_denial .cancel_btn:active {
    background: #FFFFFF;
    border: 1px solid #EE3439;
    color: #EE3439;
    margin-right: 10px;
}

@media (max-width: 375px) {
    .end-success-auction button {
        width: 100%;
    }
}
</style>